import React, { useState, useEffect } from 'react';
import PhotoWidgetSingle from '../../../../components/AdminComponents/PhotoWidgetsSingle'
import FormInput from '../../../../components/Inputs/FormInputs';
import { GenPostMultiform } from '../../../../api_client/api';
import { Alert } from '@mui/material';

function NovaFoto({refresh, setRefresh}) {
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState({
        descricao:'',
        imagem:null,
      });

    const [validationErrors, setValidationErrors] = useState({
        descricao:'',
        imagem:''
      });      

    const handleDescricao = (name, value) =>{
        setData({
            ...data, 
            descricao:value
        })

        setValidationErrors({
            ...validationErrors,
            descricao: value ? "" : "Campo obrigatório.",
          });
    }

    const formDesc = {
        'type':'string',
        'maskType':'texto',
        'name':'descricao',
        'label':'Descrição',
        'maxLenght':10,
        'required':true,
        'id':'descricao'
      }

      useEffect(() => {
        console.log(data);
      }, [data]);
      
    const handlePost = () =>{
      const errors = {};
      let hasErrors = false;

      for (const key in data) {
        if (data[key] === "" ) {
          errors[key] = "Campo obrigatório.";
          hasErrors = true;
        }
      }

      setValidationErrors(errors);

      if (hasErrors) {
        setShowError(true);
        setMsg("Por favor, preencha todos os campos.");

        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);

        return;
      }

      if (data.imagem === null) {
        setShowError(true);
        setMsg("Por favor, Envie uma foto.");

        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);

        return;
      }

        async function postData() {
          try {
            const response = await GenPostMultiform("/carrocel/store", data)
            setScssMsg(response.message);
            console.log(response)
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
            setRefresh(true);

          } catch (err) {
            setScssMsg(err.message);
            console.log(err);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        postData();
      }

  return (
    <div className='w-[100%] flex flex-col text-left'>

    <PhotoWidgetSingle data={data} setData={setData} noOptions={true}/>
    <div className='flex flex-row w-full p-4'>
        <div className='w-[50%]'>
          <FormInput data={formDesc} onInputChange={handleDescricao}/>
        </div>

        <div className='w-[50%] flex align-bottom'>
            <button className='p-2 bg-blue-400 h-[34px] self-center mt-[10px] font-semibold rounded-md ml-4 px-3' onClick={handlePost}>Enviar</button>
            {msg ? 
              <div >
                <Alert severity="info" sx={{width:320}} > {msg} </Alert>
              </div>
            : null}
            {scssMsg ? 
              <div >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </div>
            : null}
        </div>
    </div>
    

  </div>
  )
}

export default NovaFoto