
import { AdminClient, AdminFormMulti, PublicClient } from "./axios";
import { appurl } from "../config";
import axios from "axios";


async function GenPost(url, data) {
  try{
    const response = await AdminClient.post(url, data);
    return response.data
  
  } catch (err) {
    throw err;
  }
}

async function GenPostMultiform(url, data) {
    try{
      const response = await AdminFormMulti.post(url, data);
      return response.data
    
    } catch (err) {
      throw err;
    }
}

async function GenDel(url) {
  try{
    const response = await AdminClient.delete(url);
    return response.data
  
  } catch (err) {
    throw err;
  }
}


async function GenPut(url, data) {
  try{
    const response = await AdminClient.put(url, data);
    return response.data
  
  } catch (err) {

  }
}

async function GenGetAdmin(url) {
  try{
    const response = await AdminClient.get(url);
    return response.data
  
  } catch (err) {

  }
}
async function GenPostAdmin(url, data) {
  try{
    const response = await AdminClient.post(url, data);
    return response.data
  
  } catch (err) {

  }
}
async function GenPatchAdmin(url, data) {
  try{
    const response = await AdminClient.patch(url, data);
    return response.data
  
  } catch (err) {

  }
}

async function GenGetPublic(url) {
    try{
      const response = await PublicClient.get(url);
      return response.data
    
    } catch (err) {
  
    }
  }
  async function GenPostPublic(url, data) {
    try{
      const response = await PublicClient.post(url, data);
      return response.data
    
    } catch (err) {
  
    }
  }


export {
    GenPost,
    GenPostAdmin,
    GenPostMultiform,    
    GenDel,
    GenPut,
    GenPatchAdmin,
    GenGetAdmin,
    GenGetPublic,
    GenPostPublic,
  }