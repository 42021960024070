import React, { useState } from 'react'
import Carrocel from './Carrossel/Carrocel'
import NovaFoto from './Carrossel/NovaFoto'
import ParamsForm from './ParamsForm'

function Configuracoes() {
  const [refresh, setRefresh] = useState(false)
  return (
    <div className='w-[100%] flex flex-col text-left'>
      <h2 className='input-classes text-lg'>Carrocel de imagens</h2>
      <Carrocel refresh={refresh} setRefresh={setRefresh}/>
      <NovaFoto refresh={refresh} setRefresh={setRefresh}/>
      <hr/>
      <ParamsForm/>
    </div>
  )
}

export default Configuracoes