import React, { useState } from 'react'
import { GenPost } from '../../api_client/api';
import { Alert } from '@mui/material';

function FormInputsLeads() {
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [formData, setFormData] = useState({
        email:           "",
        nome:            "",
        status:          ""
      });
      const [validationErrors, setValidationErrors] = useState({
        email:           "",
        nome:            "",
        status:          ""
      });
    const handleChange = (e) => {
        const { name, value } = e.target;    
        setFormData({
          ...formData,
          [name]: value,
        });
        setValidationErrors({
            ...validationErrors,
            [name]: value ? "" : "Campo obrigatório.",
        });
    };

    const handlePost = () =>{
        const errors = {};
        let hasErrors = false;
    
        for (const key in formData) {
            if (formData[key] === "") { 
            errors[key] = "Campo obrigatório.";
            hasErrors = true;
            }
        }
    
        setValidationErrors(errors);
    
        if (hasErrors) {
            setShowError(true);
            setMsg("Por favor, preencha todos os campos.");
    
            setTimeout(() => {
            setShowError(false);
            setMsg(null);
            }, 4000);
    
            return;
        }
 
        async function postData() {
        try {
            const response = await GenPost("/newlead", formData)
            setScssMsg(response.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
        } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
        }
        }
        postData();
    };


  return (
    <div className='p-4'>
        <h3 className='font-bold'>Cadastrar novo Lead</h3>
        <br/>
        <div className='flex flex-col gap-4'>
            <div className='flex flex-row justify-between md:w-2/4 w-full'>
                <label className='mr-2 w-[100px]'>Email: 
                </label>
                <input className='flex flex-grow min-w-[250px]  bg-slate-300 rounded'
                id="email"
                name="email"
                onChange={handleChange} />
            </div>
            <div className='flex flex-row justify-between md:w-2/4 w-full'>
                <label className='mr-2 w-[100px]'>Nome: 
                </label>
                <input className='flex flex-grow min-w-[250px]  bg-slate-300 rounded'
                id="nome"
                name="nome"
                onChange={handleChange} />
            </div>
            <div className='flex flex-row justify-between md:w-2/4 w-full'>
                <label className='mr-2 w-[100px]' htmlFor="status">Status:</label>
                <select 
                    className='flex flex-grow min-w-[250px] bg-slate-300 rounded'
                    id="status"
                    name="status"
                    onChange={handleChange}>
                    <option value="ativo">ativo</option>
                    <option value="inativo">inativo</option>
                </select>
            </div>
            <div className='flex flex-row justify-end md:w-2/4 w-full'>
            {msg ? (
                <div className='h-[40px]'>
                <Alert severity="info" > {msg} </Alert>
                </div>) : (null)}
            <button className='bg-cyan-600 h-[40px] text-white p-2 rounded-md' onClick={handlePost}>Cadastrar</button>
            </div>
        </div>
    </div>
  )
}

export default FormInputsLeads