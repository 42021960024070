import React, { useState, useEffect } from 'react';
import NovaCategoria from './Categorias/NovaCategoria';
import { GenGetAdmin, GenPostAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';

function Categorias() {
    const [refresh, setRefresh] = useState(false);
    const [scssMsg, setScssMsg] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    async function getData() {
        try {
          const response = await GenGetAdmin("/categorias");
          setData(response);
          setLoading(false);
          setRefresh(false); // Set refresh to false after data is fetched
        } catch (err) {
          console.error(err);
        }
      } 
      
      useEffect(() => {
        getData();
      }, []);
    
      useEffect(() => {
        if (refresh) {
          getData();
        }
      }, [refresh]);

      const handleDelete = (id) => {
        async function removeCategoria(id) {
          try {
            const response = await GenPostAdmin(`/categorias/destroy/${id}`);
            setScssMsg(response.message);
            console.log(response);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
            setRefresh(true);
          } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        removeCategoria(id);
      };

  return (
    <div className='w-[100%] flex flex-col text-left'>
        <NovaCategoria refresh={refresh} setRefresh={setRefresh}/>
        <h3 className='input-classes text-md'>Categorías:</h3>
        <div className='flex flex-row gap-2 flex-wrap w-full p-2'>
            {loading?(
                    <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
                    <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
                      <div className='flex flex-col items-center'>
                        <CircularProgress />
                      </div>
                    </div>
                  </div>  
            ):(<div className='p-2 flex flex-col gap-1'>

            {data && Array.isArray(data) && data.length > 0 ? data.map(item =>(
                <div className='flex-row h-[40px] bg-slate-200 gap-2 px-4 p-2 flex justify-center align-middle items-center font-semibold relative border-1 rounded-md border-red-300'>
                    <p>{item.nome}: </p>
                    <p>{item.descricao}</p>
                    <span className='p-0 h-[25px] w-[25px] flex justify-center align-middle items-center absolute -top-3 -right-3 bg-red-500 hover:bg-red-400 cursor-pointer shadow-md rounded-full text-slate-100 font-semibold' onClick={() => handleDelete(item.id)}>
                        <p>x</p>
                    </span>

                </div>
            )) : <div className='h-[50px] flex items-center'><p>Nenhuma categoria cadastrada</p></div>}</div>
            )}
        </div>
    </div>
    
  )
}

export default Categorias