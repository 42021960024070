import React, { useState, useEffect } from 'react'
import { GenGetAdmin, GenPostAdmin } from '../../../../api_client/api';
import CustomSelect from '../../../../components/AdminComponents/CustomSelect';
import { Alert } from '@mui/material';

function NovoDestaque({refresh, setRefresh}) {
  const [loading, setLoading] = useState(true);
  const [scssMsg, setScssMsg] = useState(null);
  const [showErrors, setShowError] = useState(false);

  const [msg, setMsg] = useState(null);
  const [receitas, setReceitas] = useState({})
  
  const [formData, setFormData] = useState({
    tipo:'',	
    receita_id:''	
  });

  const [validationErrors, setValidationErrors] = useState({
    tipo:'',	
    receita_id:''	
  });

  const handleReceita = (id) =>{
    setFormData({
        ...formData,
        receita_id: id,
      });

      setValidationErrors({
        ...validationErrors,
        receita_id: id ? "" : "Campo obrigatório.",
    });
    }

  const handleTipo = (id) =>{
    setFormData({
          ...formData,
          tipo: id,
        });

    setValidationErrors({
          ...validationErrors,
          tipo: id ? "" : "Campo obrigatório.",
      });
    }

    useEffect(() => {
    }, [refresh]);
    
  useEffect(() => {
    async function getData() {
      try {
        const response = await GenGetAdmin("/receitas-list")
        setReceitas(response);
        setLoading(false)
        console.log(response)
      } catch (err) {
      }
    }
    getData();
  }, []);
  

  const handlePost = () =>{
    const errors = {};
    let hasErrors = false;

    for (const key in formData) {
      if (formData[key] === "" ) {
        errors[key] = "Campo obrigatório.";
        hasErrors = true;
      }
    }

    setValidationErrors(errors);

    if (hasErrors) {
      setShowError(true);
      setMsg("Por favor, preencha todos os campos.");

      setTimeout(() => {
        setShowError(false);
        setMsg(null);
      }, 4000);

      return;
    }

    async function postData() {
      try {
        const response = await GenPostAdmin("/destaques/store", formData)
        setScssMsg(response.message);
        setRefresh(true);
        setFormData({
          tipo:'',	
          receita_id:''	
        });
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        

      } catch (err) {
        setScssMsg(err.message);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      }
    }
    postData();
  }

  return (
    <div className='w-[100%] flex flex-col text-left'>
    <h2 className='input-classes text-md'>Novo Destaque</h2>
    <div className='flex flex-row gap-4'>
      <CustomSelect data={receitas} label={'Selecione a Receita'} onChange={handleReceita} search={true}/>
      <CustomSelect data={{'destaque':'Destaques', 'tendencias':'Tendências', 'novidades':'Novidades'}} label={'Selecione o tipo'} onChange={handleTipo} search={false}/>
      <button className='p-2 bg-blue-400 self-center font-semibold rounded-sm ml-2 px-3' onClick={handlePost}>Cadastrar</button>
    </div>
    {msg ? (
      <div className='h-[40px]'>
        <Alert severity="info" > {msg} </Alert>
      </div>) : (null)}
    
</div>
  )
}

export default NovoDestaque