import { CircularProgress } from '@mui/material';
import React, {useEffect, useState} from 'react'
import { AdminClient } from '../../api_client/axios';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


function DynamicFormRender({ data }) {
 
      return (
        <div>
        <h2>{data.tipo}</h2>
        {data.subitens.map((item, id) => (
        <p className="ml-4" key={id} id={item.id}>
            - {item.valor}
        </p>
        ))}

        </div>
      );
};

function FormTypes({ type, ModalState }) {
        const TodoList = () => {
          const { setShowModal, saveNewItem } = ModalState;
          const [formObjects, setFormObjects] = useState({
            'Titulo': 'Passo a Passo',
            1:''
          });
        
          const handleChange = (id, value) => {
            setFormObjects({
              ...formObjects,
              [id]: value,
            });
          };
        
          const newFormObject = () => {
            const newId = Object.keys(formObjects).length + 1;
            setFormObjects({
              ...formObjects,
              [newId]: '',
            });
          };
        
          const removeFormObject = (id) => {
            const { [id]: removedField, ...restFields } = formObjects;
            setFormObjects(restFields);
          };
        
          const keys = Object.keys(formObjects);
          return (
            <div className='flex justify-between flex-col min-w-[300px]'>
              {keys.map((key, index) => (
                key !== 'Titulo' &&
                <div key={key} className='flex flex-row gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
                  {index === keys.length - 2 && ( 
                    <HighlightOffIcon className='absolute -top-6 -right-4 m-2 bg-red-500 text-white rounded-full -w-[40px] -h-[40px] cursor-pointer' onClick={() => removeFormObject(key)}/>
                  )}
                  <label htmlFor={`passo${key}`} className='m-1 min-w-[130px]'>Passo {key}:</label>
                  <input
                    className="bg-slate-100 p-2 rounded-lg"
                    type="text"
                    id={`passo${key}`}
                    value={formObjects[key]}
                    onChange={(e) => {
                      handleChange(key, e.target.value);
                    }}
                  /><br />
                </div>
              ))}
              <div className='flex flex-row-reverse gap-2 p-2 rounded-md m-1'>
              <AddCircleOutlineIcon fontSize='large' style={{color: 'grey'}} onClick={newFormObject} className='cursor-pointer rounded-full'/>
              </div>
              <div className='flex justify-end'>
                <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
                <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
              </div>
            </div>
          );
        };
        const Ingredientes = () => {
          const { setShowModal, saveNewItem } = ModalState;
          const [formObjects, setFormObjects] = useState({
            'Titulo': 'Ingredientes',
            1:''
          });
        
          const handleChange = (id, value) => {
            setFormObjects({
              ...formObjects,
              [id]: value,
            });
          };
        
          const newFormObject = () => {
            const newId = Object.keys(formObjects).length + 1;
            setFormObjects({
              ...formObjects,
              [newId]: '',
            });
          };
        
          const removeFormObject = (id) => {
            const { [id]: removedField, ...restFields } = formObjects;
            setFormObjects(restFields);
          };
        
          const keys = Object.keys(formObjects);
          return (
            <div className='flex justify-between flex-col min-w-[300px]'>
              {keys.map((key, index) => (
                key !== 'Titulo' &&
                <div key={key} className='flex flex-row gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
                  {index === keys.length - 2 && ( // Renderiza o botão "x" apenas para o penúltimo item
                    <HighlightOffIcon className='absolute -top-6 -right-4 m-2 bg-red-500 text-white rounded-full -w-[40px] -h-[40px] cursor-pointer' onClick={() => removeFormObject(key)}/>
                  )}
                  <label htmlFor={`passo${key}`} className='m-1 min-w-[130px]'>Item {key}:</label>
                  <input
                    className="bg-slate-100 p-2 rounded-lg"
                    type="text"
                    id={`passo${key}`}
                    value={formObjects[key]}
                    onChange={(e) => {
                      handleChange(key, e.target.value);
                    }}
                  /><br />
                </div>
              ))}
              <div className='flex flex-row-reverse gap-2 p-2 rounded-md m-1'>
              <AddCircleOutlineIcon fontSize='large' style={{color: 'grey'}} onClick={newFormObject} className='cursor-pointer rounded-full'/>
              </div>
              <div className='flex justify-end'>
                <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
                <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
              </div>
            </div>
          );
        };
        const Comment = () => {
          const { setShowModal, saveNewItem } = ModalState;
          const [formObjects, setFormObjects] = useState({ 1: '', 'Titulo': 'Comentário' });
        
          const handleChange = (id, value) => {
            setFormObjects({
              ...formObjects,
              1: value,
            });
          };
          
          return (
            <div className='flex justify-between flex-col min-w-[300px]'>
      
                <div className='flex flex-col gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
                  <label htmlFor={1} className='m-1 min-w-[130px]'>Comentário:</label>
                  <textarea
                    className="bg-slate-100 p-2 rounded-lg" // Use a classe 'resize-none' para impedir o redimensionamento
                    id={1}
                    value={formObjects[1]}
                    onChange={(e) => handleChange(1, e.target.value)}      
                  />
                </div>
              <div className='flex justify-end'>
                <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
                <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
              </div>
            </div>
          );
        };
        
        const formTypes = {
          'todolist': TodoList,
          'ingredients':Ingredientes,
          'comment':Comment
        };
        const FormComponent = formTypes[type];
        return <FormComponent />;
}

function DynamicInput({ ModalState }) {
        const GlobalInfo = ModalState;
        const [formData, setFormData] = useState({});
        const [selectedType, setSelectedType] = useState('todolist');
      
        const handleTypeChange = (e) => {
          setSelectedType(e.target.value);
        };
      
        return (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-4 rounded-lg flex flex-col justify-between">
              <div className='flex justify-between gap-2'>
                <label htmlFor="tipo" className='m-1 min-w-[80px]'>Tipo:</label>
                <select
                  id="tipo"
                  className='p-2 rounded-lg'
                  value={selectedType}
                  onChange={handleTypeChange}
                >
                  <option value="todolist">Passo a passo</option>
                  <option value="ingredients">Ingredientes</option>
                  <option value="comment">Comentário</option>
                </select>
                <br />
              </div>
              <FormTypes type={selectedType} setFormData={setFormData} ModalState={GlobalInfo}/>
            </div>
          </div>
        );
}

function EditReceitaItens({id}) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ nome: '' });
    const [refresh, setRefresh] = useState(false);

    async function getReceitas() {
        try {
          const response = await AdminClient(`/receitas/adminreceitas/${id}`);
              setData({ ...data, itens: response.data.itens})     
              setLoading(false);
              setRefresh(false);
              console.log(response.data.itens)
        } catch (err) {
        }
      }

    useEffect(() => {
        if (id) {
            getReceitas();
          }
    }, [id]);

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            getReceitas();
          }
    }, [refresh]);

    const newItem = () => { 
        setShowModal(true);
      };
    
      const saveNewItem = async (obj) => {
        const { Titulo, ...rest } = obj;
        const itens = Object.entries(rest).filter(([key, value]) => !isNaN(key));

        let newItem = {
            titulo: Titulo,
            receita_id: id,
            items: itens
            }
        console.log(newItem)
        const response = await AdminClient.post(`receita/add-item`, newItem)

        setRefresh(true);
        setShowModal(false);
      };
    
      const deleteItem = async (id) => {
        const response = await AdminClient.post(`receita/remover-item/${id}`)
        setRefresh(true);
      };  

  return (
    <>{!loading ? (
    <div>
        <div>
        {data.itens.map((item, index) => (
                <div key={index} className='h-[300] bg-slate-200 flex w-full justify-end rounded-xl border-4 border-dotted my-2 p-10'>
                    <div className='w-[100%]'>
                    <DynamicFormRender data={item} formData={formData} setFormData={setFormData} />
                    </div>
                    <button className='ml-2 p-2 bg-red-500 text-white rounded-full w-[40px] h-[40px] -mt-8 -mr-8 py-2' onClick={() => deleteItem(item.id)}>x</button>
                </div>
        ))}
        </div>
        <div className='flex w-full justify-center rounded-xl border-4 border-dotted'>
            <button className='p-2 rounded-full m-2 bg-red-300 hover:bg-red-400' onClick={newItem}>Novo item</button>
        </div>
        {showModal && <DynamicInput ModalState={{ setShowModal, saveNewItem }} />}
    </div>
        ) :(
        <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
            <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
                <div className='flex flex-col items-center'>
                    <CircularProgress />
                </div>
            </div>
        </div>
    
        )

    }</>
    
  )
}

export default EditReceitaItens