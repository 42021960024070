import React, {useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useRouter  } from 'react-router-dom';
import { AppContext } from '../../App';

import{
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  InputAdornment,
  Alert} from "@mui/material";
  import VisibilityIcon from '@mui/icons-material/Visibility';
  import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { appurl } from '../../config';

const LOGIN_URL = `${appurl}/api/auth/authenticate`;

export default function Login() {

  const[user, setUser] = useState('');
  const[pwd, setPwd] = useState('');
  const userRef = useRef();
  const[statetoken, setToken] = useState(null); 
  const[errMsg, setErrMsg] = useState(null); 
  const[scssMsg, setScssMsg] = useState(null); 
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const token = localStorage.getItem('token');
  
useEffect(()=> {
    getUser();
  },[])

  useEffect(()=>{
    setToken(token)
    if (statetoken){
      navigate('/admin');
    }
  },[statetoken])

  async function getUser(){
    await axios.get(`${appurl}/sanctum/csrf-cookie`);
  }

const handleSubmit = async (e) => {
    e.preventDefault();

    try{
      const response = await axios({
        url: LOGIN_URL,
        method: 'POST',
        data: JSON.stringify({email: user, password: pwd}),
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',},
        withCredentials:true,     
      });

      localStorage.setItem('token' , response?.data?.token);   
      setToken(response?.data?.token)  
        setScssMsg('Login com feito sucesso');
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        if (location.state?.from){
          navigate(location.state.from);
        }
        window.location.reload()
      
    } catch (err) {
      setErrMsg('Falha em Login.')
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  };

  return (
    
    <Container component="main" maxWidth="xs">
      <Box
        sx={{  
          margin: 8,
          
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Faça seu Login
        </Typography>
        {errMsg ? 
          <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
            <Alert severity="error" sx={{width:320}} > {errMsg} </Alert>
          </Box>
        : null}

        {scssMsg ? 
          <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
            <Alert severity="success" sx={{width:320}} > {scssMsg} </Alert>
          </Box>
        : null}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            ref={userRef}
            label="Seu email"
            name="email"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Sua senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
