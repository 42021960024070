import React, { useState, useEffect } from 'react'
import { GenGetAdmin, GenPostAdmin } from '../../../../api_client/api';
import CustomSelect from '../../../../components/AdminComponents/CustomSelect';
import FormInput from '../../../../components/Inputs/FormInputs';
import { Alert } from '@mui/material';

function EnviarAvisoPublico({refresh,setRefresh}) {
    const [users, setUsers]= useState({});
    const [loading, setLoading] = useState(true);
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [formData, setFormData] = useState({
        titulo:'',
        aviso:'',
        tipo:'',	
        publico:''	
      });

    const [validationErrors, setValidationErrors] = useState({
        titulo:'',
        aviso:'',
        tipo:'',	
        publico:''	
      });

    useEffect(() => {
        async function getData() {
          try {
            const response = await GenGetAdmin("/users-list")
            setUsers(response);
            setLoading(false)
            console.log(response)
          } catch (err) {
          }
        }
        getData();
      }, []);

    const handlePublico = (id) =>{
        setFormData({
            ...formData,
            publico: id,
        });
        setValidationErrors({
          ...validationErrors,
          publico: id ? "" : "Campo obrigatório.",
        });
    }
    
    const handleTipo = (id) =>{
        setFormData({
            ...formData,
            tipo: id,
        });
        setValidationErrors({
          ...validationErrors,
          tipo: id ? "" : "Campo obrigatório.",
        });
    }
    
    const handleForm = (name, value) =>{
        setFormData({
            ...formData,
            [name]:value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: value ? "" : "Campo obrigatório.",
        });
    }

    const handlePost = () =>{
      const errors = {};
      let hasErrors = false;
  
      for (const key in formData) {
        if (formData[key] === "") { 
          errors[key] = "Campo obrigatório.";
          hasErrors = true;
        }
      }
  
      setValidationErrors(errors);
  
      if (hasErrors) {
        setShowError(true);
        setMsg("Por favor, preencha todos os campos.");
  
        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);
  
        return;
      }

      console.log(formData)
        async function postData() {
          try {
            const response = await GenPostAdmin("/avisos/campanha/nova", formData)
            setScssMsg(response.message);
            setRefresh(true);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
    
          } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        postData();
      }

  return (
    <div className='p-1 text-left'>
        <h3 className='input-classes text-md'>Enviar Aviso em massa</h3>
        <div className='flex flex-row gap-2 p-2'>
            <CustomSelect data={{'ativos':'Todos usuários Ativos'}} label={'Selecione o Público'} onChange={handlePublico} search={true}/>
            <CustomSelect data={{'mensagem':'Mensagem', 'notificacao':'Notificação', 'lembrete':'Lembrete'}} label={'Selecione o tipo'} onChange={handleTipo} search={false}/>
        </div>
        <div className='flex flex-col gap-2 w-full text-left p-1 mt-2'>
        <div className='max-w-[200px]'>
            <FormInput data={{
                'type':'string',
                'maskType':'texto',
                'name':'titulo',
                'label':'Título',
                'maxLenght':10,
                'required':true,
                'id':'titulo'
            }} onInputChange={handleForm} error={!showErrors? false: validationErrors.titulo }/>
        </div>

        <FormInput data={{
           'type':'string',
           'maskType':'texto',
           'name':'aviso',
           'label':'Aviso',
           'maxLenght':45,
           'required':true,
           'id':'aviso'
         }} onInputChange={handleForm} error={!showErrors? false: validationErrors.aviso }/>
        </div>
         <div className='flex flex-row w-full justify-end pr-2 -mt-2'>
            {msg ? (
                <div className='h-[40px]'>
                  <Alert severity="info" > {msg} </Alert>
                </div>) : (null)}
            <button className='p-2 bg-blue-400 hover:bg-blue-300 h-[40px] w-[100px] self-center font-semibold rounded-sm ml-2 px-3' onClick={handlePost}>Enviar</button>
         </div>
        
    </div>
  )
}

export default EnviarAvisoPublico