import React, { useState, useEffect } from 'react';
import { GenGetAdmin, GenPostAdmin } from '../../../../api_client/api';
import { appurl } from '../../../../config';

function Carrocel({refresh, setRefresh}) {
  const [scssMsg, setScssMsg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})
  
  async function getData() {
    try {
      const response = await GenGetAdmin("/carrocel/index")
      setData(response);
      setLoading(false);
      setRefresh(false)
    } catch (err) {
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refresh === true){
      getData();
    }
  }, [refresh]);

  const handleDelete = (id) =>{
    async function removeImagem(id) {
      try {
        const response = await GenPostAdmin(`/carrocel/destroy/${id}`)
        setScssMsg(response.message);
        console.log(response)
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        setRefresh(true)
  
      } catch (err) {
        setScssMsg(err.message);
        console.log(err);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      }
    }
    removeImagem(id)
  }



// /mycarrocel -> listar / /carrocel/store -> cadastrar / /carrocel/destroy/{id} -> escluir
  return (
    <div className='w-[100%] flex flex-col text-left p-2'>
      <h2 className='input-classes text-md'>Carrocel</h2>
      {loading?(
        <div>carregando</div>
      ):(
        <div className='w-full flex flex-row gap-3 overflow-x-scroll p-2'>
          {data && data.map(item =>(
            <div className='relative min-h-[120px] min-w-[190px] max-h-[160px] max-w-[230px] p-2 rounded-sm bg-slate-200 flex flex-col justify-center'>
              <img className='rounded-sm' src={`${appurl}/${item.path}`}/>
              <button className='absolute -bottom-1 -right-1 bg-red-500 hover:bg-red-400 text-slate-100 px-2 rounded-md h-[30px]' onClick={()=>handleDelete(item.id)}>Remover</button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Carrocel