import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { GenPost } from "../../api_client/api";

const DataGrid = ({data}) => {
  const nav = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [disponiveis, setDisponiveis] = useState('disponiveis');
  const [arquivados, setArquivados] = useState(false);
  const [res, setData] = useState({});
  const [filterText, setFilterText] = useState('');

  const dataGrid = (res) => {
    if (res && Array.isArray(res)) {
      return res.map((item) => ({
        id: item.id,
        nome: item.nome,
        acessos:item.acessos,
      }));
    } else {
      return [];
    }
  };
  const [gridData, setGridData] = useState(dataGrid(data.disponiveis))
  
  const currentItems = (gridData || []).slice(indexOfFirstItem, indexOfLastItem);


  const handleCheckboxChange = (checkbox) => {
    setDisponiveis(checkbox === 'disponiveis');
    setArquivados(checkbox === 'arquivadas');
    setGridData(
      checkbox === 'arquivadas' ? dataGrid(data.arquivadas) :
      dataGrid(data.disponiveis)
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((gridData?.length || 0) / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };



  const handleDump = async (id) => {
    try {
      const response = await GenPost(`/receitas/dump/${id}`);
      setData(response);
    } catch (error) {
      console.error('Erro ao atualizar o registro:', error);
      // Trate o erro conforme necessário
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.scrollTop = 0;
  }, [currentPage]);

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterText(value);

    if (value === '') {
      setGridData(disponiveis ? dataGrid(data.disponiveis) : dataGrid(data.arquivadas));
    } else {
      const filteredData = dataGrid(disponiveis ? data.disponiveis : data.arquivadas).filter(item => 
        item.nome.toLowerCase().includes(value)
      );
      setGridData(filteredData);
      setCurrentPage(1);
    }
  };

  return (<>
   
    <div className="container mx-auto my-4 ">
      <div className="overflow-x-auto h-[350px] w-[100%]">
      <div className="flex justify-between">
            <div className="flex gap-1">
            <input
                className="rounded-md p-2 bg-slate-200 m-1"
                placeholder="Pesquisar"
                onChange={handleFilter}
                value={filterText}
              />
              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={disponiveis}
                  onChange={() => handleCheckboxChange('disponiveis')}
                />
                <p className="-mt-2">Disponíveis</p>
                
              </label>

              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={arquivados}
                  onChange={() => handleCheckboxChange('arquivadas')}
                />
                <p className="-mt-2">Arquivadas</p>
              </label>
            </div>
          </div>
        <table ref={tableRef} className="min-w-full ">   
          <thead className="">

            <tr>
              <th className="px-2 py-3 bg-gray-300 text-left w-[60px]">ID</th>
              <th className="px-2 py-3 bg-gray-300 text-left w-[140px]">Titulo</th>
              <th className="px-2 py-3 bg-gray-300 text-left w-[60px]">Acessos</th>
              <th className="px-2 py-3 bg-gray-300 text-left w-[140px]">Opções</th>
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-2 py-4  w-[60px]"><div className=" flex justify-between">{item.id}</div></td>
                  <td className="px-2 py-4  w-[140px]"><div className=" flex justify-between">{item.nome}</div></td>
                  <td className="px-2 py-4  w-[60px]"><div className=" flex justify-between">{item.acessos}</div></td>
                  <td className="px-2 py-4 w-[140px]">
                    <div className=" flex justify-between">
                      <button onClick={() => nav(`edit/${item.id}`)}>Editar</button>
                      <button onClick={() => handleDump(item.id)}><DeleteIcon sx={{color: "#363636", "&:hover": { color: "#696969" }}}/></button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                  <td className="px-6 py-4"><div className=" flex justify-between w-24"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-72"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-32"></div></td>
                  <td className="px-6 py-4">
                    <div className=" flex justify-between w-32">
                      
                    </div>
                  </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center">
      {pageNumbers && pageNumbers.length > 0 ? (
        pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`mx-2 px-3 py-2 rounded-md ${
              currentPage === pageNumber
                ? "bg-red-300 text-pink-950"
                : "bg--pink-950 text-red-300"
            }`}
            onClick={() => handleChangePage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))
      ) : (
        <p className="mx-2 px-3 py-2">Nenhuma página disponível.</p>
      )}
      </div>
    </div>
    </>
  );
};

export default DataGrid;
