import React, { useState, useEffect } from 'react';
import FormInput from '../../../components/Inputs/FormInputs';
import { GenPostAdmin } from '../../../api_client/api';
import { Alert } from '@mui/material';

function ParamsForm() {
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState({
        facebook:'',
        instagram:'',
        site:''
      });

    const [validationErrors, setValidationErrors] = useState({
        facebook:'',
        instagram:'',
        site:''
      });
      const handleForm = (name, value) =>{
        setData({
            ...data, 
            [name]:value
        })

        setValidationErrors({
            ...validationErrors,
            [name]: value ? "" : "Campo obrigatório.",
          });
    }

    const inputData = [
     {
        'type':'string',
        'maskType':'texto',
        'name':'facebook',
        'label':'Facebook',
        'maxLenght':10,
        'required':true,
        'id':'facebook',
        'validationErrors':validationErrors.facebook
      },
      {
        'type':'string',
        'maskType':'texto',
        'name':'instagram',
        'label':'Instagram',
        'maxLenght':10,
        'required':true,
        'id':'instagram',
        'validationErrors':validationErrors.instagram
      },
      {
        'type':'string',
        'maskType':'texto',
        'name':'site',
        'label':'Site / Cursos',
        'maxLenght':10,
        'required':true,
        'id':'site',
        'validationErrors':validationErrors.site
      }
    ];
    const handlePost = () =>{
        const errors = {};
        let hasErrors = false;
    
        for (const key in data) {
          if (data[key] === "") { 
            errors[key] = "Campo obrigatório.";
            hasErrors = true;
          }
        }
    
        setValidationErrors(errors);
    
        if (hasErrors) {
          setShowError(true);
          setMsg("Por favor, preencha todos os campos.");
    
          setTimeout(() => {
            setShowError(false);
            setMsg(null);
          }, 4000);
    
          return;
        }
  
          async function postData() {
            try {
              const response = await GenPostAdmin("/params/store", data)
              setScssMsg(response.message);
              setData({
                facebook:'',
                instagram:'',
                site:''
              });
              setTimeout(() => {
                setScssMsg(null);
              }, 3000);
  
            } catch (err) {
              setScssMsg(err.message);
              setTimeout(() => {
                setScssMsg(null);
              }, 3000);
            }
          }
          postData();
        }
  

  return (
    <div className='w-[100%] flex flex-col text-left p-2'>
    <h2 className='input-classes text-md'>Links de rodapé</h2>

    <div className='flex flex-row w-full p-4'>       
          {inputData.map(item=>(
            <div className='w-[50%]' key={item.id}>
                <FormInput data={item} onInputChange={handleForm} error={!showErrors? false: item.validationErrors}/>
            </div>
          ))}     
        
        <div className='w-[50%] flex align-bottom'>
            <button className='p-2 bg-blue-400 h-[35px] self-center mt-[10px] font-semibold rounded-md ml-4 px-3' onClick={handlePost}>Enviar</button>
        </div>
    </div>

    <div className='w-[50%] flex align-bottom'>
        {msg ? (
              <div className='h-[40px]'>
                <Alert severity="info" > {msg} </Alert>
              </div>) : (null)}
        {scssMsg ? 
              <div >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </div>
            : null}
    </div>
    </div>
  )
}

export default ParamsForm