import React, { useRef, useState, useEffect } from 'react';

function VideoWidgetSingle({ data, setData, noOptions }) {
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && droppedFile.type.startsWith('video/')) {
      setData({ ...data, video: droppedFile });
    }
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('video/')) {
      setData({ ...data, video: selectedFile });
    }
  };

  const handleRadioChange = (e) => {
    const value = e.target.value === 'true';
    setData({ ...data, capa: value });
  };

  const removeVideo = () => {
    setData({ video: null });
  };

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const updateComponentWidth = () => {
    const width = window.innerWidth;
    const isMobile = width <= 600;
    setComponentWidth(isMobile ? '100%' : '70%');
    setComponentDirection(isMobile ? 'col' : 'row');
    setVideoCompWidth(isMobile ? '100%' : '49%');
    setMobile(isMobile ? true : false);
  };

  const [componentWidth, setComponentWidth] = useState('70%');
  const [videoCompWidth, setVideoCompWidth] = useState('49%');
  const [componentDirection, setComponentDirection] = useState('col');
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    updateComponentWidth();
    window.addEventListener('resize', updateComponentWidth);
    return () => {
      window.removeEventListener('resize', updateComponentWidth);
    };
  }, []);

  return (
    <div className='p-2'>
      <h3 className='input-classes text-md'>Novo vídeo: </h3>
      <div className={`flex flex-${componentDirection} items-center align-middle`}>
        <div
          className={`h-[200px] w-[${videoCompWidth}] m-1 flex flex-col bg-slate-300 rounded-lg p-4 justify-center items-center cursor-pointer relative`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          {data.video ? (
            <>
              <span
                className='absolute bg-slate-500 rounded-full pl-2 pr-2 -pt-1 text-white -right-2 -top-1 cursor-pointer opacity-80 flex justify-center items-center'
                onClick={removeVideo}
              >
                x
              </span>
              <video src={URL.createObjectURL(data.video)} className='h-full rounded-md' controls />
            </>
          ) : (
            <>
              <p className="text-center">Arraste e solte um vídeo aqui ou clique</p>
              <p className="text-center">para adicionar um vídeo</p>
              <input
                type='file'
                onChange={handleFileSelect}
                accept='video/*'
                hidden
                ref={inputRef}
              />
            </>
          )}
        </div>

        {!noOptions && (
          <div className={`flex flex-col m-1 justify-center align-middle items-center w-[${videoCompWidth}]`}>
            <p className='font-semibold'>Tornar esse vídeo capa?</p>
            <div className='flex'>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value={true}
                  checked={data.capa === true}
                  onChange={handleRadioChange}
                  className="form-radio h-5 w-5 text-green-500 checked:bg-green-600"
                />
                <span className="ml-2 text-gray-700">Sim</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  value={false}
                  checked={data.capa === false}
                  onChange={handleRadioChange}
                  className="form-radio h-5 w-5 text-red-500 checked:bg-red-600"
                />
                <span className="ml-2 text-gray-700">Não</span>
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoWidgetSingle;
