import React, {useEffect, useState} from 'react'
import { GenGetAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';
import DataGridAssinaturas from '../../../components/AdminComponents/DataGridAssinaturas';

function Assinaturas() {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [assinaturas, setAssinaturas] = useState([]);
  const [list, setList] = useState([]);

  async function getAssinaturas() {
    try {
      const response = await GenGetAdmin(`/assinaturas/index`);
      console.log(response);
      setAssinaturas(response);
      setLoading(false);
    } catch (err) {
    }
  }
  useEffect(() => {
    getAssinaturas();    
  }, []);


  return (
  <>
  {!loading ?(
    <div>
      <h2 className='text-left font-semibold'>Assinaturas</h2>
        <DataGridAssinaturas data={assinaturas} />
    </div>
    ):(
          <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
          <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
              <div className='flex flex-col items-center'>
                  <CircularProgress />
              </div>
          </div>
      </div>
  )}
  </>
    
  )
}

export default Assinaturas