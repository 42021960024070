import React, { useState } from 'react'
import { GenPostAdmin } from '../../../api_client/api';
import FormInput from '../../../components/Inputs/FormInputs';
import { Alert } from '@mui/material';

function NovoUsuario() {
    const [data, setData]= useState({});
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);

      const [formData, setFormData] = useState({
          name:                   "",
          email:                  "",   
          telefone:               "",
          password:               "", 
          password_confirmation:  "",
        });

      const [validationErrors, setValidationErrors] = useState({
        name:                     "",
        email:                    "",   
        telefone:                 "",
        password:                 "", 
        password_confirmation:    "",
        });

        const handleChange = (name, value) => {
          setFormData({
            ...formData,
            [name]: value,
          });
      
          setValidationErrors({
            ...validationErrors,
            [name]: value ? "" : "Campo obrigatório.",
          });
        };


      const handlePost = () =>{
        const errors = {};
        let hasErrors = false;
    
        for (const key in formData) {
          if (formData[key] === "") { 
            errors[key] = "Campo obrigatório.";
            hasErrors = true;
          }
        }
    
        setValidationErrors(errors);
    
        if (hasErrors) {
          setShowError(true);
          setMsg("Por favor, preencha todos os campos obrigatórios.");
    
          setTimeout(() => {
            setShowError(false);
            setMsg(null);
          }, 4000);
    
          return;
        }

        async function postData() {
          try {

            const response = await GenPostAdmin("/auth/register", formData)
            setData(response);
            setMsg(response.message)
            setTimeout(() => setMsg(null), 3000);

          } catch (err) {
            setMsg(err.message)
            setTimeout(() => setMsg(null), 3000);
          }
        }
        postData();
      }

  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
                    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Nova conta de usuário</h2>
                            <div className='flex sm:flex-row flex-col justify-between  mr-4'>
                              <div className='w-full sm:w-1/2'>
                              <FormInput data={{
                                'type': 'string',
                                'maskType': 'texto',
                                'name': 'name',
                                'label': 'Nome',
                                'maxLenght': 10,
                                'required': true,
                                'id': 'nome'
                              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.name }/>
                              </div>
                              <div className='w-full sm:w-1/2'>
                              <FormInput data={{
                                'type': 'string',
                                'maskType': 'telefone',
                                'name': 'telefone',
                                'label': 'Telefone',
                                'maxLenght': 14,
                                'required': true,
                                'id': 'telefone'
                              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.telefone }/>
                              </div>
                            </div>

                            <div className='flex flex-col justify-between'>
                            <FormInput data={{
                                'type': 'string',
                                'maskType': 'email',
                                'name': 'email',
                                'label': 'Email',
                                'maxLenght': 200,
                                'required': true,
                                'id': 'email'
                              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.email }/>
                            </div>

                            <div className='flex sm:flex-row flex-col justify-between gap-2 mr-4'>
                              <div className='w-full sm:w-1/2'>
                              <FormInput data={{
                                'type': 'string',
                                'maskType': 'senha',
                                'name': 'password',
                                'label': 'Senha',
                                'maxLenght': 200,
                                'required': true,
                                'id': 'email'
                              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.password }/>
                              </div>
                              <div className='w-full sm:w-1/2'>
                              <FormInput data={{
                                'type': 'string',
                                'maskType': 'senha',
                                'name': 'password_confirmation',
                                'label': 'Confirme a Senha',
                                'maxLenght': 200,
                                'required': true,
                                'id': 'password_confirmation'
                              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.password_confirmation }/>
                              </div>
                            </div>
                            
                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                {msg ? (
                                  <div className='h-[40px]'>
                                    <Alert severity="info" > {msg} </Alert>
                                  </div>) : (null)}
                                <button className='bg-cyan-600 w-[200px] h-[40px] rounded hover-bg-cyan-500' onClick={handlePost}>
                                    Cadastrar
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
  )
}

export default NovoUsuario