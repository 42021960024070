import React, { useState, useEffect } from 'react';
import logo from '../../../assets/brand/Logo.png'
import Bio from '../../../assets/brand/Nani.jpg'
import PlayStore from '../../../assets/icons/google-play.png'
// Componente da barra de navegação
const Navbar = ({ toggleShowTerms }) => {
  return (
    <nav className="bg-white shadow-lg p-4 sticky top-0">
      <div className="container mx-auto flex justify-between items-center gap-2">
        <div className='w-[150px] h-[70px] overflow-hidden'>
          <img src={logo} alt="Foto do logo" className='-mt-[20px]'/>
        </div>

        <div className='flex flex-row gap-2'>
          <a href="/" onClick={() => toggleShowTerms(false)}>Página Inicial</a>
          <a href="#terms" onClick={() => toggleShowTerms(true)}>Termos de Uso</a>
        </div>
      </div>
    </nav>
  );
}

// Componente do rodapé
const Footer = () => {
  return (
    <footer className="bg-white py-4">
      <div className="container mx-auto text-center">
        <p className="text-gray-600">© {new Date().getFullYear()} Confeitaria Nani Magalhães. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

// Componente do layout compartilhado
const Layout = ({ children, toggleShowTerms }) => {
  return (
    <div className="min-h-screen bg-red-300 flex flex-col">
      <Navbar toggleShowTerms={toggleShowTerms} />
      {children}
      <Footer />
    </div>
  );
}

const ConfeitariaSection = () => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className='p-2 m-4 text-left max-w-[80vw] bg-slate-200 rounded-md'>
      <div className="flex justify-between items-center cursor-pointer" onClick={toggleContent}>
        <h2 className="text-2xl font-bold text-red-950 mb-2">Descubra o Mundo da Confeitaria</h2>
        <svg className={`w-6 h-6 transition-transform duration-300 transform ${showContent ? 'rotate-180' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div className={`transition-opacity duration-300 ${showContent ? 'opacity-100 ' : 'opacity-0 h-[10px]'} overflow-hidden`}>
        <p className="text-md font-serif text-red-950 leading-relaxed mb-8 p-2">
          <span className="font-semibold">Foi pensando em você</span>, confeiteira, que surgiu a criação desse <span className="font-semibold">aplicativo!</span>
          <br/>
          Criado por <span className="font-semibold">Nani Magalhães</span>, grande confeiteira renomada <span className="font-semibold">no Brasil e no mundo!</span>
          <br/><br/>
          Mais de <span className="font-semibold">20 mil alunas</span> e <span className="font-semibold">vidas transformadas</span> nesses x anos de história e nesse incrível mundo que é a confeitaria!
          <br/>
          Faça parte e conte com a experiência e receitas selecionadas pela Nani Magalhães e sua equipe!
        </p>
      </div>
    </div>
  );
}

// Componente da página inicial
const LandingPage = ({ toggleShowTerms }) => {
  
  return (
    <Layout toggleShowTerms={toggleShowTerms}>
      {/* Seção principal */}
      <div className="container mx-auto flex flex-col items-center justify-center flex-grow mt-2">
        <h2 className="text-4xl font-bold mb-4 text-red-950 ">Bem-vindo(a) à Profissão Confeiteira!</h2>
        <p className="text-lg text-center mb-8 text-red-950 -mt-2">Explore muitas deliciosas e sempre atualizadas receitas, tutoriais e muito mais!</p>
        <div className="rounded-full w-64 h-64 mb-8 overflow-hidden" >
          <img src={Bio} alt="Foto da confeiteira Nani Magalhães" />
        </div>
        <p className="text-xl font-bold mb-4 text-red-950">Planos a partir de R$90,00 por ano!</p>
        <div className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex justify-center align-middle items-center cursor-pointer gap-1">
          <p> Baixe Agora na Play Store</p> <img src={PlayStore} className='w-[40px]' alt={''}/>
        </div>


        <div className='p-2 m-4 text-left w-[80vw] bg-slate-200 rounded-md'>
        <ConfeitariaSection />
        </div>
      </div>
    </Layout>
  );
}

// Componente dos termos de uso
const TermsOfUse = ({ toggleShowTerms }) => {
  return (
    <Layout toggleShowTerms={toggleShowTerms}>
      {/* Seção de termos de uso */}
      <div id="terms" className="container mx-auto p-8">
        <h2 className="text-2xl font-bold mb-4">Política de Uso</h2>
          <div className="min-h-screen bg-red-300 flex flex-col p-1">
            <div className="bg-white p-8 rounded-lg shadow-lg text-left">
              <h2 className="text-2xl font-bold mb-4">Política de Uso</h2>
              <p className="mb-4">
                <strong>Introdução</strong><br />
                Bem-vindo ao nosso Aplicativo de Receitas! Esta Política de Uso e Privacidade descreve como coletamos, utilizamos e protegemos as informações dos usuários do nosso aplicativo. Ao utilizar nosso aplicativo, você concorda com os termos aqui descritos.
              </p>
              <p className="mb-4">
                <strong>Informações Coletadas</strong><br />
                Coletamos informações de interação dos usuários dentro do aplicativo para melhorar nossos serviços e oferecer uma experiência personalizada. As informações coletadas incluem:
                <ul className="list-disc ml-6">
                  <li>Dados de navegação e interação com o aplicativo.</li>
                  <li>Preferências de receitas e histórico de visualizações.</li>
                  <li>Informações fornecidas diretamente pelo usuário, como nome e e-mail.</li>
                </ul>
              </p>
              <p className="mb-4">
                <strong>Uso das Informações</strong><br />
                As informações coletadas são utilizadas para:
                <ul className="list-disc ml-6">
                  <li>Melhoria dos Serviços: Analisar interações e preferências para aprimorar as funcionalidades do aplicativo.</li>
                  <li>Campanhas Publicitárias: Personalizar campanhas publicitárias de acordo com os interesses dos usuários.</li>
                  <li>Campanhas de Desconto: Oferecer descontos e promoções de parceiros diretamente no aplicativo.</li>
                  <li>Conteúdo de Parceiros: Incluir conteúdo relevante de parceiros, especialmente relacionado à confeitaria e interesses mútuos.</li>
                </ul>
              </p>
              {/* Adicione os demais parágrafos de acordo com os termos de uso fornecidos */}
              <p className="mb-4">
                <strong>Aceitação dos Termos</strong><br />
                Ao utilizar nosso aplicativo, você aceita e concorda com os termos desta Política de Uso e Privacidade.
              </p>
            </div>
          </div>
      </div>
    </Layout>
  );
}

// Componente principal que decide qual componente renderizar com base na rota
const MainIndex = () => {
  const [showTerms, setShowTerms] = useState(false);

  // Função para alternar entre a página inicial e os termos de uso
  const toggleShowTerms = (show) => {
    setShowTerms(show);
    window.scrollTo({ top: 0});
  }

  return (
    <>
      {showTerms ? <TermsOfUse toggleShowTerms={toggleShowTerms} /> : <LandingPage toggleShowTerms={toggleShowTerms} />}
    </>
  );
}

export default MainIndex;
