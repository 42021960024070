import React, {useEffect, createContext, useState }from 'react';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';

import AdminMain from './AdminRoot/AdminMain';
import MainIndex from './MainRoot/scenes/index/MainIndex';
import Terms from './MainRoot/scenes/index/Terms';
import Login from './AdminRoot/scenes/Login';
import { ReqUserAuth } from './components/RequireAuth';
import NewLayout from './components/NewLayout';

export const AppContext = createContext();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  const [logging, setLogging] = useState(false);

  return (
    <div className="App">
        <BrowserRouter>
        
        <ScrollToTop />
        <AppContext.Provider value={{setLogging}}> 
          <Routes>  
              <Route element={<ReqUserAuth />}>
                <Route element={<NewLayout/>}>
                  <Route path="/admin/*" element={<AdminMain />} />
                </Route> 
              </Route>
              <Route path="/*" element={<MainIndex />} />
              <Route path='/terms' element={<Terms/>} />
              <Route path='/login' element={<Login />}/>
          </Routes>
          </AppContext.Provider> 
        
        </BrowserRouter>
    </div>
  );
}

export default App;

{/* atalho para criar componente - rfce */}

