import React, { useState, useEffect } from 'react';
import PhotoWidget from '../../../components/AdminComponents/PhotoWidget';
import { GenGetAdmin, GenPostMultiform } from '../../../api_client/api';
import FormInput from '../../../components/Inputs/FormInputs';
import FormDinamicInputV2 from '../../../components/Inputs/FormDinamicInputV2';
import CustomSelect from '../../../components/AdminComponents/CustomSelect';
import { Alert } from '@mui/material';

function NovaReceita() {
  const [itenData, setItensData] = useState({});
  const [data, setData] = useState({ fotos: [] });
  const [showErrors, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [categorias, setCategorias] = useState({});

  const [msg, setMsg] = useState(null);

  const [formData, setFormData] = useState({
    nome: "",
    subtitulo: "",
    porcoes: "",
    dificuldade: "",
    descricao: "",
    link_conteudo: "",
    status: 1,
    premium: false,
    sujestao_valor_venda: "",
    tempo_estimado: "",
    valor_de_investimento: "",
    categoria_id: "",
    fotos: [],
  });

  const [validationErrors, setValidationErrors] = useState({
    nome: "",
    subtitulo: "",
    porcoes: "",
    dificuldade: "",
    descricao: "",
    link_conteudo: "",
    status: "",
    sujestao_valor_venda: "",
    tempo_estimado: "",
    valor_de_investimento: "",
  });

  const HandleItens = (data) => {
    setItensData(data);
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [name]: value ? "" : "Campo obrigatório.",
    });
  };

  const handlePost = () => {
    const errors = {};
    let hasErrors = false;

    for (const key in formData) {
      if (formData[key] === "" && key !== "link_conteudo") { // link_conteudo is not required
        errors[key] = "Campo obrigatório.";
        hasErrors = true;
      }
    }

    setValidationErrors(errors);

    if (hasErrors) {
      setShowError(true);
      setMsg("Por favor, preencha todos os campos obrigatórios.");

      setTimeout(() => {
        setShowError(false);
        setMsg(null);
      }, 4000);

      return;
    }
    async function postData() {
      try {
        console.log(formData)
        const response = await GenPostMultiform("/receitas/store", formData);
        setMsg(response.message)
        setFormData({
          nome: "",
          subtitulo: "",
          porcoes: "",
          dificuldade: "",
          descricao: "",
          link_conteudo: "",
          status: 1,
          premium: false,
          sujestao_valor_venda: "",
          tempo_estimado: "",
          valor_de_investimento: "",
          categoria_id: "",
          fotos: [],
        })
        setItensData({});
        setTimeout(() => setMsg(null), 3000);

      } catch (err) {
        setMsg(err.message)
        setTimeout(() => setMsg(null), 3000);
      }
    }
    postData();
  };

  const handleAtivo = (id) =>{
    const ativa = id === "ativa" ? 1 : 0
    setFormData({
        ...formData,
        status: ativa,
    });
  }

  const handleCategria = (id) =>{
    setFormData({
        ...formData,
        categoria_id: parseInt(id),
    });
  }

  const handleDificuldade = (id) =>{
    setFormData({
        ...formData,
        dificuldade: id,
    });
  }

  const handlePremium = (e) => {     
    setFormData({
        ...formData,
        premium: e.target.checked,
    });    
}

  useEffect(() => {
    setFormData({
      ...formData,
      itens: itenData,
    });
  }, [itenData]);

  useEffect(() => {
    async function getCategorias() {
      try {
        const response = await GenGetAdmin("/categorias", formData);
        setCategorias(  
            response.reduce((acc, item) => {
            acc[item.id] = item.nome;
            return acc;
          }, {})
        );

        setIsLoading(false)

      } catch (err) {
        // loga erro
      }
    }
    getCategorias();


    }, []);

///categorias
  useEffect(() => {
    setFormData({
      ...formData,
      fotos: data.fotos,
    });
  }, [data]);

  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-full sm:w-[85vw] h-[89vh] p-2'>
        <div className='flex flex-col text-left overflow-hidden'>
          <h2 className='input-classes text-lg'>Novo Receita</h2>
          <br />
          <h5 className='mb-4 font-semibold text-lg'>Sobre: </h5>
          <div className='flex flex-col sm:flex-row w-full sm:w-[98%]'>
            <div className='flex flex-col w-full sm:w-[50%]'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'texto',
                'name': 'nome',
                'label': 'Nome',
                'maxLenght': 10,
                'required': true,
                'id': 'nome'
              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.nome }/>
            </div>
            <div className='flex flex-col w-full sm:w-[50%]'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'subtitulo',
                'name': 'subtitulo',
                'label': 'Subtítulo',
                'maxLenght': 10,
                'required': true,
                'id': 'subtitulo'
              }} onInputChange={handleChange} error={!showErrors? false:  validationErrors.subtitulo}/>
            </div>
          </div>
          <FormInput data={{
            'type': 'string',
            'maskType': 'texto',
            'name': 'descricao',
            'label': 'Descrição',
            'maxLenght': 100,
            'required': true,
            'id': 'descricao'
          }} onInputChange={handleChange} error={!showErrors? false: validationErrors.descricao}/>
          <div className='flex flex-col sm:flex-row gap-2 w-full sm:w-[96%]'>
            <div className='flex flex-row justify-start gap-2 p-2'>
              <label className='font-semibold'>Premium:</label>
              <input className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="premium"
                  name="premium"
                  type="checkbox"
                  checked={formData.premium}
                  onChange={handlePremium}  
                />
            </div>
          </div>
          <div className='flex ml-4 flex-row gap-2 flex-grow justify-start'>
            
              <div className='mb-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={data.id}>
                Ativa
              </label>
              <CustomSelect data={{'ativa':'Ativa', 'inativa':'Inativa'}} label={'Selecione'} onChange={handleAtivo} search={false}/>
              </div>
              <div className='mb-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={data.id}>
                Categoria
              </label>
              <CustomSelect data={categorias} label={'Selecione'} onChange={handleCategria} search={true}/>
              </div>
              <div className='mb-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={data.id}>
                Dificuldade
              </label>
              <CustomSelect data={{'facil':'Facil', 'media':'Media', 'dificil':'Dificil'}} label={'Selecione'} onChange={handleDificuldade} search={false}/>
              </div>
            </div>
          <h5 className='mb-4 font-semibold text-lg'>Detalhes: </h5>
          <div className='flex flex-col sm:flex-row justify-between'>
            <div className='w-full sm:w-2/3'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'url',
                'name': 'link_conteudo',
                'label': 'Link do conteudo',
                'maxLenght': 254,
                'id': 'link_conteudo'
              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.status}/>
            </div>
            <div className='w-full sm:w-1/3'>
              <FormInput data={{
                'type': 'number',
                'maskType': 'porcoes',
                'name': 'porcoes',
                'label': 'Porções',
                'maxLenght': 10,
                'required': true,
                'id': 'porcoes'
              }} onInputChange={handleChange}  error={!showErrors? false: validationErrors.porcoes}/>
            </div>      
            
          </div>
          <div className='flex flex-col sm:flex-row justify-between'>
            <div className='w-full sm:w-1/3'>
              <FormInput data={{
                'type': 'number',
                'maskType': 'valor_real',
                'name': 'sujestao_valor_venda',
                'label': 'Sujestao de valor para a venda',
                'maxLenght': 10,
                'required': true,
                'id': 'sujestao_valor_venda'
              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.sujestao_valor_venda}/>
            </div>
            <div className='w-full sm:w-1/3'>
              <FormInput data={{
                'type': 'number',
                'maskType': 'valor_real',
                'name': 'valor_de_investimento',
                'label': 'Valor de investimento',
                'maxLenght': 10,
                'required': true,
                'id': 'valor_de_investimento'
              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.valor_de_investimento} />
            </div>
            <div className='w-full sm:w-1/3'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'hora',
                'name': 'tempo_estimado',
                'label': 'Tempo estimado',
                'maxLenght': 10,
                'required': true,
                'id': 'tempo_estimado'
              }} onInputChange={handleChange}  error={!showErrors? false: validationErrors.tempo_estimado}/>
            </div>
          </div>
          {data ? (<PhotoWidget setData={setData} data={data} />) : null}
          <br />
          <h2 className=' text-lg'>Modo de Preparo</h2>
          <br />
          <FormDinamicInputV2 HandleItens={HandleItens} />
          <div className='flex w-full justify-end'>
          {msg ? (
            <div className='h-[40px]'>
              <Alert severity="info" > {msg} </Alert>
            </div>) : (null)}
            <button className='bg-cyan-600 w-[200px] h-[40px] rounded hover-bg-cyan-500' onClick={handlePost}>
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NovaReceita;
