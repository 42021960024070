import React from 'react'

function UsabilidadeIndex() {
  return (
    <div>
      <h3 className='font-semibold'>Painel de Usabilidade</h3>
      <p>será implementado em breve</p>
    </div>
  )
}

export default UsabilidadeIndex