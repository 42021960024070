import React, { useState, useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function DynamicFormRender({ data, formData }) {
  const renderValue = (innerKey, innerValue) => {
    if (innerKey === 'Título') {
      return formData[innerKey];
    }

    if (innerValue.includes('\n')) {
      const lines = innerValue.split('\n');
      return (
        <div key={innerKey}>
          {lines.map((line, index) => (
            <p className="ml-4" key={`${innerKey}-${index}`} id={`${innerKey}-${index}`}>
              {line}
            </p>
          ))}
        </div>
      );
    }

    return (
      <p className="ml-4" key={innerKey} id={`${innerKey}`}>
        {innerValue}
      </p>
    );
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          {Object.entries(value).map(([innerKey, innerValue]) => (
            innerKey === 'Titulo' ? (
              <h1 className='input-classes' key={innerKey} id={`${key}-${innerKey}`}>
                {innerValue}
              </h1>
            ) : (null)
          ))}
          {Object.entries(value).map(([innerKey, innerValue]) => (
            innerKey === 'Titulo' ? (
              null
            ) : (
              renderValue(innerKey, innerValue)
            )
          ))}
          <br />
        </div>
      ))}
    </>
  );
}

function FormTypes({ type, ModalState }) {
  const TodoList = () => {
    const { setShowModal, saveNewItem } = ModalState;
    const [formObjects, setFormObjects] = useState({
      'Titulo': 'Passo a Passo',
      1:''
    });
  
    const handleChange = (id, value) => {
      setFormObjects({
        ...formObjects,
        [id]: value,
      });
    };
  
    const newFormObject = () => {
      const newId = Object.keys(formObjects).length + 1;
      setFormObjects({
        ...formObjects,
        [newId]: '',
      });
    };
  
    const removeFormObject = (id) => {
      const { [id]: removedField, ...restFields } = formObjects;
      setFormObjects(restFields);
    };
  
    const keys = Object.keys(formObjects);
    return (
      <div className='flex justify-between flex-col min-w-[300px]'>
        {keys.map((key, index) => (
          key !== 'Titulo' &&
          <div key={key} className='flex flex-row gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
            {index === keys.length - 2 && ( 
              <HighlightOffIcon className='absolute -top-6 -right-4 m-2 bg-red-500 text-white rounded-full -w-[40px] -h-[40px] cursor-pointer' onClick={() => removeFormObject(key)}/>
            )}
            <label htmlFor={`passo${key}`} className='m-1 min-w-[130px]'>Passo {key}:</label>
            <input
              className="bg-slate-100 p-2 rounded-lg"
              type="text"
              id={`passo${key}`}
              value={formObjects[key]}
              onChange={(e) => {
                handleChange(key, e.target.value);
              }}
            /><br />
          </div>
        ))}
        <div className='flex flex-row-reverse gap-2 p-2 rounded-md m-1'>
        <AddCircleOutlineIcon fontSize='large' style={{color: 'grey'}} onClick={newFormObject} className='cursor-pointer rounded-full'/>
        </div>
        <div className='flex justify-end'>
          <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
          <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
        </div>
      </div>
    );
  };
  const Ingredientes = () => {
    const { setShowModal, saveNewItem } = ModalState;
    const [formObjects, setFormObjects] = useState({
      'Titulo': 'Ingredientes',
      1:''
    });
  
    const handleChange = (id, value) => {
      setFormObjects({
        ...formObjects,
        [id]: value,
      });
    };
  
    const newFormObject = () => {
      const newId = Object.keys(formObjects).length + 1;
      setFormObjects({
        ...formObjects,
        [newId]: '',
      });
    };
  
    const removeFormObject = (id) => {
      const { [id]: removedField, ...restFields } = formObjects;
      setFormObjects(restFields);
    };
  
    const keys = Object.keys(formObjects);
    return (
      <div className='flex justify-between flex-col min-w-[300px]'>
        {keys.map((key, index) => (
          key !== 'Titulo' &&
          <div key={key} className='flex flex-row gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
            {index === keys.length - 2 && ( // Renderiza o botão "x" apenas para o penúltimo item
              <HighlightOffIcon className='absolute -top-6 -right-4 m-2 bg-red-500 text-white rounded-full -w-[40px] -h-[40px] cursor-pointer' onClick={() => removeFormObject(key)}/>
            )}
            <label htmlFor={`passo${key}`} className='m-1 min-w-[130px]'>Item {key}:</label>
            <input
              className="bg-slate-100 p-2 rounded-lg"
              type="text"
              id={`passo${key}`}
              value={formObjects[key]}
              onChange={(e) => {
                handleChange(key, e.target.value);
              }}
            /><br />
          </div>
        ))}
        <div className='flex flex-row-reverse gap-2 p-2 rounded-md m-1'>
        <AddCircleOutlineIcon fontSize='large' style={{color: 'grey'}} onClick={newFormObject} className='cursor-pointer rounded-full'/>
        </div>
        <div className='flex justify-end'>
          <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
          <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
        </div>
      </div>
    );
  };
  const Comment = () => {
    const { setShowModal, saveNewItem } = ModalState;
    const [formObjects, setFormObjects] = useState({ 1: '', 'Titulo': 'Comentário' });
  
    const handleChange = (id, value) => {
      setFormObjects({
        ...formObjects,
        1: value,
      });
    };
    
    return (
      <div className='flex justify-between flex-col min-w-[300px]'>

          <div className='flex flex-col gap-2 bg-red-200 p-2 rounded-md m-1 relative '>
            <label htmlFor={1} className='m-1 min-w-[130px]'>Comentário:</label>
            <textarea
              className="bg-slate-100 p-2 rounded-lg" // Use a classe 'resize-none' para impedir o redimensionamento
              id={1}
              value={formObjects[1]}
              onChange={(e) => handleChange(1, e.target.value)}      
            />
          </div>
        <div className='flex justify-end'>
          <button className="p-2 bg-gray-500 text-white rounded-full m-2" onClick={() => setShowModal(false)}>Cancelar</button>
          <button className="p-2 bg-red-500 text-white rounded-full m-2" onClick={() => saveNewItem(formObjects)}>Salvar</button>
        </div>
      </div>
    );
  };
  
  const formTypes = {
    'todolist': TodoList,
    'ingredients':Ingredientes,
    'comment':Comment
  };
  const FormComponent = formTypes[type];
  return <FormComponent />;
}

function DynamicInput({ ModalState }) {
  const GlobalInfo = ModalState;
  const [formData, setFormData] = useState({});
  const [selectedType, setSelectedType] = useState('todolist');

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white p-4 rounded-lg flex flex-col justify-between">
        <div className='flex justify-between gap-2'>
          <label htmlFor="tipo" className='m-1 min-w-[80px]'>Tipo:</label>
          <select
            id="tipo"
            className='p-2 rounded-lg'
            value={selectedType}
            onChange={handleTypeChange}
          >
            <option value="todolist">Passo a passo</option>
            <option value="ingredients">Ingredientes</option>
            <option value="comment">Comentário</option>
          </select>
          <br />
        </div>
        <FormTypes type={selectedType} setFormData={setFormData} ModalState={GlobalInfo}/>
      </div>
    </div>
  );
}

function FormDinamicInputV2({HandleItens}) {

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ nome: '' });
  const [data, setData] = useState([]);
  
  useEffect(() => {
    HandleItens(data);
  }, [data]);

  const newItem = () => { 
    setShowModal(true);
  };

  const saveNewItem = (obj) => {
    let len = data.length + 1;
    const newItem = {
      [len]: obj
    };
    setData([...data, newItem]);
    setShowModal(false);
    HandleItens(data);
  };

  const deleteItem = (keyToDelete) => {
    const updatedData = data.filter(item => !item[keyToDelete]);
    setData(updatedData);
  };  

  return (
    <div className='mt-2 mb-20 p-4 flex flex-col'>
      <div>
        {data.map((item, index) => (
          <div key={index} className='h-[300] bg-slate-200 flex w-full justify-end rounded-xl border-4 border-dotted my-2 p-4'>
            <div className='w-full'>
              <DynamicFormRender data={item} formData={formData} setFormData={setFormData} />
            </div>
            <button className='ml-2 p-2 bg-red-500 text-white rounded-full w-[40px] h-[40px] -mt-8 -mr-8 py-2' onClick={() => deleteItem(Object.keys(item)[0])}>x</button>
          </div>
        ))}
      </div>
      <div className='flex w-full justify-center rounded-xl border-4 border-dotted'>
        <button className='p-2 rounded-full m-2 bg-red-300 hover:bg-red-400' onClick={newItem}>Novo item</button>
      </div>

      {showModal && <DynamicInput ModalState={{ setShowModal, saveNewItem }} />}
    </div>
  );
}

export default FormDinamicInputV2;