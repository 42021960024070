export const appurl = "https://profissaoconfeiteira.com"

export const header = {  
    titulo: 'Profissao Confeiteira',
    subtitulo: 'Aplicativo de receitas',
  };
  
export const footer = {
    facebook : '',
    instagram : '',
    whatsApp : '',
    emailAtendimento: '',
    emailTecnico: '',
    emailAdministrativo: '',
    telefone: '',
  };

export const navbar = {
    links: {
        home:'/',
        sobre:'/sobre',
        contatos:'/contatos'},
    textcolor:{
      light:'text-gray-700',
      dark :'text-slate-300',
      lightHover:'text-slate-400',
      darkHover:'text-slate-100',
    }
  };  

  export const bio = {
    nome: '',
    cargo: '',
    idade:'',    
  }; 

export const bioSlides = [
{ titulo:"",
conteudo:""
},
{ titulo:"",
conteudo:""
},
{ titulo:"",
conteudo:" "
},
]

  export const redes = {
    gradients: { 
      light : 'bg-gradient-to-tr from-yellow-800 to-yellow-200',
      dark : 'bg-gradient-to-tr from-sky-400 to-fuchsia-400'
   },
   contato : {
    whatsapp :'https://api.whatsapp.com/send?phone=',
    facebook:'https://www.facebook.com/',
    instagram:'https://www.instagram.com/',
    linkedin:'https://www.linkedin.com/in'}
  }

