import React, { useState, useEffect } from 'react';
import FormInput from '../../../../components/Inputs/FormInputs';
import { GenPostAdmin } from '../../../../api_client/api';
import { Alert } from '@mui/material';

function NovaCategoria({setRefresh}) {
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [data, setData] = useState({
        descricao:'',
        nome:'',
        premium:true
      });

    const [validationErrors, setValidationErrors] = useState({
      descricao:'',
      nome:'',
      });

    const handlePremium = (e) => {     
      setData({
            ...data,
            premium: e.target.checked,
        });    
    }
    const handleForm = (name, value) =>{
        setData({
            ...data, 
            [name]:value
        })

        setValidationErrors({
            ...validationErrors,
            [name]: value ? "" : "Campo obrigatório.",
          });
    }

    const inputData = [
     {
        'type':'string',
        'maskType':'texto',
        'name':'nome',
        'label':'Nome',
        'maxLenght':10,
        'required':true,
        'id':'nome',
        'validationErrors':validationErrors.nome
      },
      {
        'type':'string',
        'maskType':'texto',
        'name':'descricao',
        'label':'Descrição',
        'maxLenght':10,
        'required':true,
        'id':'descricao',
        'validationErrors':validationErrors.descricao
      }
    ]

      useEffect(() => {
        console.log(data);
      }, [data]);
      
    const handlePost = () =>{
      const errors = {};
      let hasErrors = false;
  
      for (const key in data) {
        if (data[key] === "") { 
          errors[key] = "Campo obrigatório.";
          hasErrors = true;
        }
      }
  
      setValidationErrors(errors);
  
      if (hasErrors) {
        setShowError(true);
        setMsg("Por favor, preencha todos os campos obrigatórios.");
  
        setTimeout(() => {
          setShowError(false);
          setMsg(null);
        }, 4000);
  
        return;
      }

        async function postData() {
          try {
            const response = await GenPostAdmin("/categorias/store", data)
            setScssMsg(response.message);
            console.log(response)
            setData({
              descricao:'',
              nome:'',
              premium:true
            });
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
            setRefresh(true);

          } catch (err) {
            setScssMsg(err.message);
            setTimeout(() => {
              setScssMsg(null);
            }, 3000);
          }
        }
        postData();
      }

  return (
    <div className='w-[100%] flex flex-col text-left'>
      <h2 className='input-classes text-md'>Nova Categoría</h2>
      <div className='flex flex-row justify-start gap-2 p-2'>
              <label className='font-semibold'>Premium:</label>
              <input className={` bg-slate-300 rounded m-2 flex p-1.5`}                 
                  id="premium"
                  name="premium"
                  type="checkbox"
                  checked={data.premium}
                  onChange={handlePremium}  
                />
            </div>
    <div className='flex flex-row w-full p-4'>       
          {inputData.map(item=>(
            <div className='w-[50%]' key={item.id}>
                <FormInput data={item} onInputChange={handleForm} error={!showErrors? false: item.validationErrors}/>
            </div>
          ))}     
        

        <div className='w-[50%] flex align-bottom'>
            <button className='p-2 bg-blue-400 h-[40px] self-center mt-[10px] font-semibold rounded-md ml-4 px-3' onClick={handlePost}>Enviar</button>
        </div>
    </div>
    
    <div className='w-[50%] flex align-bottom'>
            {msg ? (
              <div className='h-[40px]'>
                <Alert severity="info" > {msg} </Alert>
              </div>) : (null)}
            {scssMsg ? 
              <div >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </div>
            : null}
        </div>

  </div>
  )
}

export default NovaCategoria