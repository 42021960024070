import axios from "axios";
import { appurl } from "../config";
const token = localStorage.getItem('token');


export const PublicClient = axios.create({
    baseURL:`${appurl}/api`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: false,    
});


export const AdminClient = axios.create({
    baseURL:`${appurl}/api`,
    headers:{
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': `Bearer ${token}`},
        withCredentials: true,    
});

export const AdminFormMulti = axios.create({
  baseURL: `${appurl}/api`,
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
    'Authorization': `Bearer ${token}`},
    withCredentials: true,
  });