import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { GenPostAdmin, GenPostMultiform } from '../../../api_client/api';
import { useNavigate } from 'react-router-dom'
import { AdminClient } from '../../../api_client/axios';
import VideoWidgetSingle from '../../../components/AdminComponents/VideoWidgetSingle';
import PhotoWidgetSingle from '../../../components/AdminComponents/PhotoWidgetsSingle';
import { appurl } from '../../../config';

function EditarCurso() {
    const GLOBAL_URL = appurl;
    const nav = useNavigate()
    const { id } = useParams();
    const [currentMedias, setCurrentMedias] = useState({
        video:null,
        imagem: null
    })
    const [ loading, setLoading]=useState(false)
    const [data, setData] = useState({
        video:null,
        imagem: null
    });
    const [itenData, setItensData] = useState([]);
    const [itens, setItens] = useState({
    });
    
    const HandleItens = (data)=>{
        setItensData(data)
    }    

    const [formData, setFormData] = useState({
        nome: "",
        subtitulo: "",
        descricao: "",
        valor: 0,
        status: "",
        video: null,
        capa:null
    });

    const [validationErrors, setValidationErrors] = useState({
        nome: "",
        subtitulo: "",
        descricao: "",
        valor: 0,
        status: "",
    });

    const handleChangeInput = (e) => {
        const value = e.target.value;
        console.log(formData)
        setFormData({
            ...formData,
            [e.target.id]: value,
        });
    }

    const handlePost = (id) => {
        async function postData() {
            try {
                const response = await GenPostMultiform(`/cursos/update/${id}`, formData)
                setData(response);
            } catch (err) {
                console.log(err)
            }
        }
        postData();
    }


    useEffect(() => {
        setFormData({
            ...formData,
            capa: data.foto,
            video: data.video

        });
    }, [data]);

    useEffect(() => {

    }, [formData]);
    
    useEffect(() => {
        if (id) {
          async function getCurso() {
            try {
              const response = await AdminClient(`/cursos/admincursos/${id}`);
              console.log(response)
              setFormData({
                ...formData,
                nome: response.data.nome,
                subtitulo: response.data.subtitulo,
                descricao: response.data.descricao,
                valor: 0,
                status: response.data.status,
                video: null,     
                capa: null,     
              });
              setCurrentMedias({
                video: response.data.conteudos[0].video,     
                imagem: response.data.capa,    
              })
              setLoading(false);
            } catch (err) {
            }
          }
          getCurso();
        }
      }, [id]);

    return (<> 
        { !loading ?  
            (
                <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll'>
                    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Editar Curso</h2>
                            <br />
                            <h4 className='mb-4 font-semibold text-lg'>Informações:</h4>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="nome"
                                    name="nome"
                                    value={formData.nome} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Subtítulo: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="subtitulo"
                                    name="subtitulo"
                                    value={formData.subtitulo} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descrição: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="descricao"
                                    name="descricao"
                                    value={formData.descricao} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Status: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="status"
                                    name="status"
                                    value={formData.status} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <h2 className='input-classes text-lg'>Conteúdo atual: </h2>
                            <div className='flex flex-row gap-4'>
                            <img
                                src={`${GLOBAL_URL}/storage/imagens/capas/${currentMedias.imagem}`}
                                className="w-[200px] h-auto aspect-square shadow-md rounded-sm"
                                alt={`Foto`}
                            />
                            <video 
                                src={`${GLOBAL_URL}/storage/videos/conteudo_aulas/${currentMedias.video}`} 
                                className="w-[200px] aspect-video  shadow-md rounded-sm" 
                                controls
                            >
                                Seu navegador não suporta a exibição deste vídeo.
                            </video>  
                            </div>

                            
                            <br/>
                            <h2 className='input-classes text-lg'>Video: </h2>
                            <div className='flex flex-row gap-4'>
                                {data ? (<VideoWidgetSingle setData={setData} data={data} noOptions={true} />) : null}
                                {data ? (<PhotoWidgetSingle setData={setData} data={data} title={'Capa: '}  noOptions={true} />) : null}
                            </div>
                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                <button className='bg-slate-50 border w-[100px] rounded hover-bg-cyan-500' onClick={() => nav('/admin/cursos')}>
                                    Voltar
                                </button>
                                <button className='bg-cyan-600 w-[200px] rounded hover-bg-cyan-500' onClick={() => handlePost(id)}>
                                    Atualizar
                                </button>
                            </div>                           
                        </div>

                    </div>
                </div>
            ) : (
                <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
                    <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
                        <div className='flex flex-col items-center'>
                            <CircularProgress />
                        </div>
                    </div>
                </div>)
        }
        </>
    )
}
export default EditarCurso