import React, { useState } from 'react';
import Validator from '../Validations/Validate'; // Corrija o caminho do import

function FormInput({ data, onInputChange, error, values, customValue }) {
    const [inputValue, setInputValue] = useState('');
  
    const handleChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
      onInputChange(data.name, value);
    }
  
    return (
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={data.id}>
          {data.label}
        </label>

        <Validator data={data} onInputChange={handleChange} value={inputValue} customValue={customValue} error={error} values={values}/>
      </div>
    );
  }
  
  export default FormInput;
