import React, { useState } from 'react';
import logo from '../../../assets/brand/Logo.png'
import { useNavigate } from 'react-router-dom';
// Componente da barra de navegação
const Navbar = () => {
    const nav = useNavigate()
  return (
    <nav className="bg-white shadow-lg p-4 sticky top-0">
      <div className="container mx-auto flex justify-between items-center gap-2">
        <div className='w-[150px] h-[70px] overflow-hidden'>
          <img src={logo} alt="Foto do logo" className='-mt-[20px]'/>
        </div>
        <div className='flex flex-row gap-2'>
          <a href="/" onClick={() => nav('/')}>Página Inicial</a>
        </div>
      </div>
    </nav>
  );
}

// Componente do rodapé
const Footer = () => {
  return (
    <footer className="bg-white py-4">
      <div className="container mx-auto text-center">
        <p className="text-gray-600">© {new Date().getFullYear()} Confeitaria Nani Magalhães. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

// Componente do layout compartilhado
const Layout = ({ children, toggleShowTerms }) => {
  return (
    <div className="min-h-screen bg-red-300 flex flex-col">
      <Navbar toggleShowTerms={toggleShowTerms} />
      {children}
      <Footer />
    </div>
  );
}

// Componente dos termos de uso
const TermsOfUse = ({ toggleShowTerms }) => {
  return (
    <Layout toggleShowTerms={toggleShowTerms}>
      {/* Seção de termos de uso */}
      <div id="terms" className="container mx-auto p-8">
        <h2 className="text-2xl font-bold mb-4">Política de Uso</h2>
          <div className="min-h-screen bg-red-300 flex flex-col p-1">
            <div className="bg-white p-8 rounded-lg shadow-lg text-left">
              <h2 className="text-2xl font-bold mb-4">Política de Uso</h2>
              <p className="mb-4">
                <strong>Introdução</strong><br />
                Bem-vindo ao nosso Aplicativo de Receitas! Esta Política de Uso e Privacidade descreve como coletamos, utilizamos e protegemos as informações dos usuários do nosso aplicativo. Ao utilizar nosso aplicativo, você concorda com os termos aqui descritos.
              </p>
              <p className="mb-4">
                <strong>Informações Coletadas</strong><br />
                Coletamos informações de interação dos usuários dentro do aplicativo para melhorar nossos serviços e oferecer uma experiência personalizada. As informações coletadas incluem:
                <ul className="list-disc ml-6">
                  <li>Dados de navegação e interação com o aplicativo.</li>
                  <li>Preferências de receitas e histórico de visualizações.</li>
                  <li>Informações fornecidas diretamente pelo usuário, como nome e e-mail.</li>
                </ul>
              </p>
              <p className="mb-4">
                <strong>Uso das Informações</strong><br />
                As informações coletadas são utilizadas para:
                <ul className="list-disc ml-6">
                  <li>Melhoria dos Serviços: Analisar interações e preferências para aprimorar as funcionalidades do aplicativo.</li>
                  <li>Campanhas Publicitárias: Personalizar campanhas publicitárias de acordo com os interesses dos usuários.</li>
                  <li>Campanhas de Desconto: Oferecer descontos e promoções de parceiros diretamente no aplicativo.</li>
                  <li>Conteúdo de Parceiros: Incluir conteúdo relevante de parceiros, especialmente relacionado à confeitaria e interesses mútuos.</li>
                </ul>
              </p>
              {/* Adicione os demais parágrafos de acordo com os termos de uso fornecidos */}
              <p className="mb-4">
                <strong>Aceitação dos Termos</strong><br />
                Ao utilizar nosso aplicativo, você aceita e concorda com os termos desta Política de Uso e Privacidade.
              </p>
            </div>
          </div>
      </div>
    </Layout>
  );
}

// Componente principal que decide qual componente renderizar com base na rota
const MainIndex = () => {

  return (
      <TermsOfUse />
  );
}

export default MainIndex;