import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { GenPost, GenPostAdmin, GenPostMultiform } from '../../../api_client/api';
import { useNavigate } from 'react-router-dom'
import { AdminClient } from '../../../api_client/axios';
import PainelFotos from './PainelFotos';
import EditReceitaItens from '../../../components/AdminComponents/EditReceitaItens';

function EditReceitas() {
    const nav = useNavigate()
    const { id } = useParams();
    const [ loading, setLoading]=useState(false)
    const [data, setData] = useState({
        fotos: []
    });
    const [itenData, setItensData] = useState([]);
    const [itens, setItens] = useState({
    });
    
    const HandleItens = (data)=>{
        setItensData(data)
    }    

    const [formData, setFormData] = useState({
        nome:                   "",
        subtitulo:              "",  
        porcoes:                "",
        dificuldade:            "", 
        descricao:              "",
        link_conteudo:          "",
        status:                 "",
        sujestao_valor_venda:   "",
        tempo_estimado:         "",
        valor_de_investimento:  "",
        fotos: [],
    });

    const [validationErrors, setValidationErrors] = useState({
        nome:                   "",
        subtitulo:              "",  
        porcoes:                "",
        dificuldade:            "", 
        descricao:              "",
        link_conteudo:          "",
        status:                 "",
        sujestao_valor_venda:   "",
        tempo_estimado:         "",
        valor_de_investimento:  "",
        fotos: [],
    });

    const handleChangeInput = (e) => {
        const value = e.target.value;
        console.log(formData)
        setFormData({
            ...formData,
            [e.target.id]: value,
        });
    }

    const handlePost = (id) => {
        async function postData() {
            try {
                const response = await GenPostAdmin(`/receitas/update/${id}`, formData)
                console.log(response)
                setData(response);
            } catch (err) {
                console.log(err)
            }
        }
        postData();
    }


    useEffect(() => {
        setFormData({
            ...formData,
            fotos: data.fotos,
        });
    }, [data]);

    useEffect(() => {

    }, [formData]);
    
    useEffect(() => {
        if (id) {
          async function getReceitas() {
            try {
              const response = await AdminClient(`/receitas/adminreceitas/${id}`);
              setFormData({
                ...formData,
                nome: response.data.dados.nome,
                subtitulo: response.data.dados.subtitulo,
                descricao: response.data.dados.descricao,
                link_conteudo: response.data.dados.link_conteudo,
                porcoes: response.data.dados.porcoes,
                dificuldade: response.data.dados.dificuldade, 
                status: response.data.dados.status,
                sujestao_valor_venda: response.data.dados.sujestao_valor_venda,
                tempo_estimado: response.data.dados.tempo_estimado,
                valor_de_investimento: response.data.dados.valor_de_investimento,         
              });
              setItensData(response.data.itens);
              setLoading(false);
            } catch (err) {
            }
          }
          getReceitas();
        }
      }, [id]);

    return (<> 
        { !loading ?  
            (
                <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll'>
                    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Editar Receita</h2>
                            <br />
                            <h4 className='mb-4 font-semibold text-lg'>Informações:</h4>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="nome"
                                    name="nome"
                                    value={formData.nome} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descrição: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="subtitulo"
                                    name="subtitulo"
                                    value={formData.subtitulo} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descrição: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="descricao"
                                    name="descricao"
                                    value={formData.descricao} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descrição: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="dificuldade"
                                    name="dificuldade"
                                    value={formData.dificuldade} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <h4 className='mb-4 mt-8 font-semibold text-lg'>Detalhes:</h4>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Link externo: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="link_conteudo"
                                    name="link_conteudo"
                                    value={formData.link_conteudo} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Sujestão para a Venda: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="sujestao_valor_venda"
                                    name="sujestao_valor_venda"
                                    value={formData.sujestao_valor_venda} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Tempo estimado: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="tempo_estimado"
                                    name="tempo_estimado"
                                    value={formData.tempo_estimado} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Valor do Investimento: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="valor_de_investimento"
                                    name="valor_de_investimento"
                                    value={formData.valor_de_investimento} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Status: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="status"
                                    name="status"
                                    value={formData.status} 
                                    onChange={handleChangeInput}  
                                    />
                            </div>
                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                <button className='bg-slate-50 border w-[100px] rounded hover-bg-cyan-500' onClick={() => nav('/admin/receitas')}>
                                    Voltar
                                </button>
                                <button className='bg-cyan-600 w-[200px] rounded hover-bg-cyan-500' onClick={() => handlePost(id)}>
                                    Cadastrar
                                </button>

                            </div>
                            {id && <PainelFotos pointer={id}/>}
                            <br />

                            <h2 className='mb-4 mt-8 font-semibold text-lg'>Instruções:</h2>
                            <br />
                            <EditReceitaItens id={id}/>
                            
                        </div>

                    </div>
                </div>
            ) : (
                <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
                    <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
                        <div className='flex flex-col items-center'>
                            <CircularProgress />
                        </div>
                    </div>
                </div>)
        }
        </>
    )
}
export default EditReceitas