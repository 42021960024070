import React, { useState, useEffect } from 'react'
import DataGridTickets from '../../../components/AdminComponents/DataGridTickets'
import { GenGetAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';

function PainelTickets() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})
  const [gridData, setGridData] = useState({})
  const [refresh, setRefresh] =useState(false);
  
  async function getData() {
    try {
      const response = await GenGetAdmin("/tickets")
      setData(response);
      setGridData(response);
      setLoading(false);
      setRefresh(false);
    } catch (err) {
    }
  }

  useEffect(() => {
      getData();
    }, []);

    
  return (<>
    { !loading ? (
      <div className='rounded-md flex flex-grow overflow-x-hidden overflow-y-hidden'>
    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
    <div className='flex flex-col text-left'>
        <h2 className='input-classes text-lg'>Tickets</h2>
        <DataGridTickets data={gridData} refresh={refresh} setRefresh={setRefresh}/>
        <div className='flex flex-col'>
          <div className='flex justify-start gap-4 mt-4 p-4 lg:flex-row flex-col'>
                <div className='flex flex-row'>
                  <p>Tickets abertos: </p>  
                  <p className='ml-2'>{data.total_aberto}</p>
                </div>
                <div className='flex flex-row'>
                  <p>Tickets fechados: </p>  
                  <p className='ml-2'>{data.total_resolvido}</p>
                </div>

          </div>
        </div>
    </div>
    </div>
  </div>
    ) : (
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <CircularProgress />
        </div>
      </div>
    </div>
    )}
  </>
  )
}

export default PainelTickets