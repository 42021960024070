import React, { useState, useEffect } from 'react';
import PhotoWidget from '../../../components/AdminComponents/PhotoWidget';
import { GenGetAdmin, GenPostMultiform } from '../../../api_client/api';
import FormInput from '../../../components/Inputs/FormInputs';
import FormDinamicInputV2 from '../../../components/Inputs/FormDinamicInputV2';
import CustomSelect from '../../../components/AdminComponents/CustomSelect';
import { Alert } from '@mui/material';
import VideoWidgetSingle from '../../../components/AdminComponents/VideoWidgetSingle';
import PhotoWidgetSingle from '../../../components/AdminComponents/PhotoWidgetsSingle';

function NovoCurso() {
  const [itenData, setItensData] = useState({});
  const [data, setData] = useState({ 
    video: null,
    imagem:null 
  });
  const [showErrors, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [msg, setMsg] = useState(null);

  const [formData, setFormData] = useState({
    nome: "",
    subtitulo: "",
    descricao: "",
    valor: 0,
    status: "",
    video: null,
    capa:null
  });

  const [validationErrors, setValidationErrors] = useState({
    nome: "",
    subtitulo: "",
    descricao: "",
    valor: 0,
    status: "",
  });


  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [name]: value ? "" : "Campo obrigatório.",
    });
  };

  const handlePost = () => {
    const errors = {};
    let hasErrors = false;

    for (const key in formData) {
      if (formData[key] === "") { // link_conteudo is not required
        errors[key] = "Campo obrigatório.";
        hasErrors = true;
      }
    }

    setValidationErrors(errors);

    if (hasErrors) {
      setShowError(true);
      setMsg("Por favor, preencha todos os campos obrigatórios.");

      setTimeout(() => {
        setShowError(false);
        setMsg(null);
      }, 4000);

      return;
    }
    async function postData() {
      try {
        console.log(formData)
        const response = await GenPostMultiform("/cursos/store", formData);
        setMsg(response.message)
        setFormData({
          nome: "",
          subtitulo: "",
          descricao: "",
          valor: "",
          status: "",
          video: null,
          capa: null,
        });
        setData({ 
          video: null,
          imagem:null 
        });
        setTimeout(() => setMsg(null), 3000);

      } catch (err) {
        setMsg(err.message)
        setTimeout(() => setMsg(null), 3000);
      }
    }
    postData();
  };

  const handleAtivo = (id) =>{
    const ativa = id === "ativo" ? 1 : 0
    setFormData({
        ...formData,
        status: ativa,
    });
  }


  useEffect(() => {
    setFormData({
      ...formData,
      video: data.video,
      capa: data.imagem,
    });
  }, [data]);

  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-full sm:w-[85vw] h-[89vh] p-2'>
        <div className='flex flex-col text-left overflow-hidden'>
          <h2 className='input-classes text-lg'>Novo Curso</h2>
          <br />
          <h5 className='mb-4 font-semibold text-lg'>Sobre: </h5>
          <div className='flex flex-col sm:flex-row w-full sm:w-[98%]'>
            <div className='flex flex-col w-full sm:w-[50%]'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'texto',
                'name': 'nome',
                'label': 'Título',
                'maxLenght': 10,
                'required': true,
                'id': 'nome'
              }} onInputChange={handleChange} error={!showErrors? false: validationErrors.nome }/>
            </div>
            <div className='flex flex-col w-full sm:w-[50%]'>
              <FormInput data={{
                'type': 'string',
                'maskType': 'subtitulo',
                'name': 'subtitulo',
                'label': 'Subtítulo',
                'maxLenght': 10,
                'required': true,
                'id': 'subtitulo'
              }} onInputChange={handleChange} error={!showErrors? false:  validationErrors.subtitulo}/>
            </div>
          </div>
          <FormInput data={{
            'type': 'string',
            'maskType': 'texto',
            'name': 'descricao',
            'label': 'Descrição',
            'maxLenght': 100,
            'required': true,
            'id': 'descricao'
          }} onInputChange={handleChange} error={!showErrors? false: validationErrors.descricao}/>

          <div className='flex ml-4 flex-row gap-2 flex-grow justify-start'>
            <div className='mb-4'>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={data.id}>
                Status
              </label>
              <CustomSelect data={{'ativo':'Ativo', 'inativo':'Inativo'}} label={'Selecione'} onChange={handleAtivo} search={false}/>
            </div>

          </div>
          <h2 className='input-classes text-lg'>Video: </h2>
          <div className='flex flex-row gap-4'>
            {data ? (<VideoWidgetSingle setData={setData} data={data} noOptions={true} />) : null}
            {data ? (<PhotoWidgetSingle setData={setData} data={data} title={'Capa: '}  noOptions={true} />) : null}
          </div>
          
          <br />
  
          <div className='flex w-full justify-end'>
          {msg ? (
            <div className='h-[40px]'>
              <Alert severity="info" > {msg} </Alert>
            </div>) : (null)}
            <button className='bg-cyan-600 w-[200px] h-[40px] rounded hover-bg-cyan-500' onClick={handlePost}>
              Cadastrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NovoCurso;
