import React from 'react'

function FinanceiroIndex() {
  return (
    <div className='flex flex-col rounded-md border-0'>
      <h3 className='font-semibold'>Painel Financeiro</h3>
      <p>será implementado em breve</p>
    </div>
  )
}

export default FinanceiroIndex