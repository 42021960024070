import React, { useState, useEffect } from 'react';
import ListarDestaques from './Destaques/ListarDestaques';
import NovoDestaque from './Destaques/NovoDestaque';
import { GenGetAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';

function Destaques() {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  async function getData() {
    try {
      const response = await GenGetAdmin("/destaques/index");
      setData(response);
      setLoading(false);
      setRefresh(false); // Set refresh to false after data is fetched
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refresh) {
      getData();
    }
  }, [refresh]);

  return (
    <div className="w-[100%] flex flex-col text-left">
      <h2 className="input-classes text-lg">Painel de Destaques</h2>
      <div className="p-2 relative" style={{ zIndex: 1 }}>
        <NovoDestaque refresh={refresh} setRefresh={setRefresh} />
      </div>
      {loading?(
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
        <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
          <div className='flex flex-col items-center'>
            <CircularProgress />
          </div>
        </div>
      </div>         
      ) : (
      <>
        {data && (
          <div className="relative" style={{ zIndex: 0 }}>
            <ListarDestaques data={data} refresh={refresh} setRefresh={setRefresh} />
          </div>
        )}
      </>
      )}
    </div>
   
  );
}

export default Destaques;
