import React, { useState, useEffect } from 'react';
import { GenGetAdmin, GenPostAdmin, GenPostMultiform } from '../../../api_client/api'; 
import PhotoWidgetSingle from '../../../components/AdminComponents/PhotoWidgetsSingle';
import { Alert, Box } from '@mui/material';
import { appurl } from '../../../config';
function PainelFotos(props) {
    const receita = props.pointer
    const [fotos, setFotos] = useState(null);
    const [pointer, setPointer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const GLOBAL_URL = appurl;
    const token = localStorage.getItem('token');
    const [scssMsg , setScssMsg] = useState(false);

    const [data, setData] = useState({
        imagem: null,
        capa:true,
        receita_id:props.pointer
      });
      

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await GenGetAdmin(`/fotos/${receita}`);
                setFotos(response);
                setPointer(response[0]);
                setRefresh(false);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setRefresh(false);
                setLoading(false);
            }
        };
        if (refresh){
          fetchData();      
        }        
    }, [refresh]);

    const handlePointer = (item) =>{
        setPointer(item)
    }

    const handleDelete = (id) =>{     
        const fetchData = async () => {
            try{
                const response = await GenPostAdmin(`/del_foto/${id}`);

                setLoading(true);
                setRefresh(true)
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
        fetchData();
    }

    const handleCapa = (id) =>{
        const fetchData = async () => {
            try{
                const response = await GenPostAdmin(`/fotos/capa/${id}`);

                setLoading(true);
                setRefresh(true)
            } catch (error) {
                console.error('Error fetching data', error);
            }
        }
        fetchData();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(data.imagem){
            try {
            const response = await GenPostMultiform('receita/imagem', data);
                setScssMsg(response.message)
                setTimeout(() => {
                    setScssMsg(null);
                }, 3000);
                setRefresh(true);
                setData({
                    imagem: null,
                    capa:true,
                    receita_id:receita
                })

            } catch (error) {
                setScssMsg("Erro ao cadastrar imagem")
                console.log(error)
                setTimeout(() => {
                    setScssMsg(null);
                }, 3000);

            }
            
        } else {
            setScssMsg("Imagem não inserida.")

        }
    };
    
    const updateComponentWidth = () => {
        const width = window.innerWidth;
        const isMobile = width <= 600; 
        setComponentWidth(isMobile ? '100%' : '70%');
        setComponentDirection(isMobile ? 'col' : 'row');
        setImgCompWidth(isMobile ? '100%' : '49%');
        setMobile(isMobile ? true : false);
      };
    
    const [componentWidth, setComponentWidth] = useState('70%');
    const [imgCompWidth, setImgCompWidth] = useState('49%');
    const [componentDirection, setComponentDirection] = useState('col');
    const [mobile, setMobile] = useState(false);
    
    useEffect(() => {
        updateComponentWidth(); 
        window.addEventListener('resize', updateComponentWidth); 
        return () => {
          window.removeEventListener('resize', updateComponentWidth);
        };
      }, []);
      
    return (
        <div className={`w-[100%] min-w[80vw]`}>
            <h3 className='mb-4 mt-8 font-semibold text-lg '>Painel de Fotos</h3>
            <div className={`flex justify-between flex-col shadow-md`}>
                <div className={`w-[100%] m-1 p-2 flex`}>
                    {pointer?(<>
                    <img src={`${GLOBAL_URL}/${pointer.path}`} 
                        className={'w-[70%] rounded-md shadow-md'} alt=''/>
                    </>):(
                    <div className='w-[100%] bg-slate-200 flex items-center justify-center'> selecione uma imagem em visualizar</div>)}
                </div>

                <div className={`flex flex-col w-[100%] max-h-[70vh] overflow-scroll no-scrollbar gap-2`}>
                {fotos ? (
                    fotos.map((item, index) => (
                        <div className="w-[100%] flex justify-between p-2 shadow-md" key={index}>
                            <img
                                src={`${GLOBAL_URL}/${item.path}`}
                                className="w-[100px] h-auto shadow-md rounded-sm"
                                alt={`Foto ${index}`}
                            />
                            {item.capa === true && !mobile ? ( <p className='font-semibold text-[11pt]'>foto de capa</p>):null}
                            <div className='flex flex-col gap-2'>
                            <div className='flex gap-2'>
                            {item.capa === true && mobile ? ( <p className='font-semibold text-[11pt]'>foto de capa</p>):null}
                            </div>
                            <div className='flex gap-2'>
                                <button className=' w-[100%] flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handleCapa(item.id)}>definir como capa</button>
                            </div>
                            <div className='flex gap-2'>
                                <button className=' flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handlePointer(item)}>Visuaizar</button>
                                <button className=' flex items-center justify-center p-1 text-[10pt] bg-yellow-500 hover:bg-yellow-400 text-slate-800 font-semibold rounded-md shadow-md' onClick={() => handleDelete(item.id)}>Remover</button>
                            </div>

                            </div>
                            
                        </div>
                    ))
                ) : (
                    <p>Loading...</p>
                )}
                </div>

 
            </div>
            <div>
            {data? <PhotoWidgetSingle setData={setData} data={data}/>  :null}
            </div>
            {scssMsg ? 
            <Box display={'flex'} flexDirection={'row-reverse'} padding={1} >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
            </Box>
            : null}
            <div className='flex w-full p-2 justify-end'> 
                <button className='bg-blue-600 hover:bg-blue-500 text-slate-100 font-semibold p-2 flex justify-center align-middle rounded-md shadow-md' onClick={handleSubmit}>cadastrar imagem</button>
            </div>

        </div>
    );
}

export default PainelFotos;