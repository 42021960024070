import React, { useState, useEffect, useRef } from "react";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { GenPost } from "../../api_client/api";

const DataGridLeads = ({data}) => {
  const [pointer, setPointer] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; 

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [disponiveis, setDisponiveis] = useState('disponiveis');
  const [arquivados, setArquivados] = useState(false);
  const [res, setData] = useState({});
  const [filterText, setFilterText] = useState('');
  const dataGrid = (res) => {
    if (res && Array.isArray(res)) {
      return res.map((item) => ({
        id: item.id,
        name: item.nome,
        description: item.valor,
      }));
    } else {
      // Se algo estiver ausente ou não for um array, retorne um array vazio ou trate conforme necessário.
      return [];
    }
  };
  const [gridData, setGridData] = useState(dataGrid(data.disponiveis))
  
  const currentItems = (gridData || []).slice(indexOfFirstItem, indexOfLastItem);


  const handleCheckboxChange = (checkbox) => {
    setDisponiveis(checkbox === 'disponiveis');
    setArquivados(checkbox === 'arquivados');
    setGridData(
      checkbox === 'arquivados' ? dataGrid(data.arquivados) :
      dataGrid(data.disponiveis)
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((gridData?.length || 0) / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleModal = (id) => {
    setModalActive(true);
    setPointer(id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await GenPost(`/cardump/${id}`);
      setData(response);
    } catch (error) {
      console.error('Erro ao atualizar o registro:', error);
      // Trate o erro conforme necessário
    }

  };

  const handleSold = async (id) => {
    try {
      const response = await GenPost(`/carsold/${id}`);
      setData(response);
    } catch (error) {
      console.error('Erro ao atualizar o registro:', error);
      // Trate o erro conforme necessário
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.scrollTop = 0;
  }, [currentPage]);
  
  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterText(value);

      if (value === '') {
        setGridData(disponiveis ? dataGrid(data.disponiveis) : dataGrid(data.arquivados));
      } else {
        const filteredData = dataGrid(disponiveis ? data.disponiveis : data.arquivados).filter(item => 
          item.name.toLowerCase().includes(value)
        );
        setGridData(filteredData);
        setCurrentPage(1);
      }
  };

  return (<> 
    <div className="container mx-auto my-4 ">
    <div className="overflow-x-auto h-[350px] w-[100%]">
      <div className="flex justify-between">
            <div className="flex gap-2">
            <input
                className="rounded-md p-2 bg-slate-200 m-1"
                placeholder="Pesquisar"
                onChange={handleFilter}
                value={filterText}
              />
              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={disponiveis}
                  onChange={() => handleCheckboxChange('disponiveis')}
                />
                <p className="-mt-2">Disponíveis</p>
                
              </label>

              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={arquivados}
                  onChange={() => handleCheckboxChange('arquivados')}
                />
                <p className="-mt-2">Arquivadas</p>
              </label>
            </div>
          </div>
        <table ref={tableRef} className="min-w-full ">
          <thead className="justify content-between">
            <tr>
              <th className="px-6 py-3 bg-gray-300 text-left w-[50px]">ID</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-[100px]">Email</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-[60px]">Nome</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-[60px]">Origem</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-[100px]">Opções</th>
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4"><div className=" flex justify-between w-[50px]">{item.id}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-[100px]">{item.email}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-[60px]">{item.nome}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-[60px]">{item.origem}</div></td>
                  <td className="px-6 py-4">
                    <div className=" flex justify-between w-[100px]">
                      <button onClick={() => handleModal(item.id)}>Editar</button>
                      <button onClick={() => handleDelete(item.id)}><DeleteIcon sx={{color: "#363636", "&:hover": { color: "#696969" }}}/></button>
                      <button onClick={() => handleSold(item.id)}><CheckIcon sx={{color: "#363636", "&:hover": { color: "#696969" }}}/></button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                  <td className="px-6 py-4"><div className=" flex justify-between w-24"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-72"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-32"></div></td>
                  <td className="px-6 py-4">
                    <div className=" flex justify-between w-32">
                      
                    </div>
                  </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center">
      {pageNumbers && pageNumbers.length > 0 ? (
        pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`mx-2 px-3 py-2 rounded-md ${
              currentPage === pageNumber
                ? "bg-red-300 text-pink-950"
                : "bg--pink-950 text-red-300"
            }`}
            onClick={() => handleChangePage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))
      ) : (
        <p className="mx-2 px-3 py-2">Nenhuma página disponível.</p>
      )}
      </div>
    </div>
    </>
  );
};

export default DataGridLeads;