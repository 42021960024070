import React, { useState, useEffect } from 'react'
import { GenGetAdmin } from '../../../../api_client/api';
import { CircularProgress } from '@mui/material';

function MeusAvisos() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [aviso, setAviso] = useState({});
  const [lidos, setLidos] = useState(false);
  const [refresh, setRefresh] = useState(false);

  async function getData() {
    try {
      const response = await GenGetAdmin("/avisos/index")
      setData(response);
      setAvisos(response['nao-lidos'])
      setLoading(false)
      console.log(response)
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (refresh) {
      getData();
      setRefresh(false); // Reset refresh after data is fetched
    }
  }, [refresh]);

  const handleLidos = (status) => {
    if (status === 'lidos') {
      setLidos(true);
      setAvisos(data.lidos);
      setAviso({});
    } else {
      setLidos(false);
      setAvisos(data['nao-lidos']);
      setAviso({});
    }
  }

  const handleAviso = (aviso) => {
    if (aviso.status === 'enviado') {
      async function postData() {
        try {
          const response = await GenGetAdmin(`avisos-status/${aviso.id}`)
          setRefresh(true);
          console.log(response.message)
        } catch (err) {
          console.error(err);
        }
      }
      postData();
    }
    setAviso(aviso);
  }

  if (loading) {
    return (
        <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
            <div className='w-[100vw] sm:w-[85vw] h-[30vh] p-2 flex items-center justify-center'>
                <div className='flex flex-col items-center'>
                <CircularProgress />
                </div>
            </div>
        </div>
    );
  }

  return (
    <div className='text-left'>
      <h3 className='input-classes text-md'>Meus Avisos</h3>
      <div className='w-full flex flex-row'>
        <div className='flex flex-col w-1/3 min-h-[300px] max-h-[500px] p-2 rounded-md'>
          <div className='w-full flex flex-row justify-between rounded-t-md'>
            <div className={`w-1/2 p-1 rounded-s-md ${!lidos ? 'bg-red-300' : 'bg-red-200'} hover:bg-red-400 cursor-pointer`} onClick={() => handleLidos('nao-lidos')}>
              <p>Não Lidos</p>
            </div>

            <div className={`w-1/2 p-1 rounded-e-md ${lidos ? 'bg-red-300' : 'bg-red-200'} hover:bg-red-400 cursor-pointer`} onClick={() => handleLidos('lidos')}>
              <p>Lidos</p>
            </div>
          </div>
          <div className='p-2 flex flex-col bg-slate-200 gap-1 overflow-y-auto mt-2 flex-grow rounded-md'>
            {avisos && avisos.map(item => (
              <div
                key={item.id}
                className='p-1 bg-slate-100 hover:bg-red-100 border-2 border-red-200 rounded-sm cursor-pointer'
                onClick={() => handleAviso(item)}
              >
                <p>{item.titulo}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='flex flex-col w-2/3 min-h-[300px] max-h-[500px] overflow-y-auto p-2 rounded-md'>
          {Object.keys(aviso).length === 0 ? (
            <>
              <div className='w-full flex flex-row bg-red-200 p-1 rounded-t-md'>
                <p>Escolha um aviso</p>
              </div>
              <div className='p-4 flex text-wrap flex-col gap-2 border-2 border-red-200 bg-slate-200 flex-grow rounded-b-md'>
                {/* Espaço reservado */}
              </div>
            </>
          ) : (
            <>
              <div className='w-full flex flex-row bg-red-200 p-1 rounded-t-md'>
                <p>{aviso.titulo}</p>
              </div>
              <div className='p-4 flex text-wrap flex-col gap-2 border-2 border-red-200 bg-slate-200 flex-grow rounded-b-md'>
                <p>{aviso.aviso}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MeusAvisos;
