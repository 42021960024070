import React, { useRef, useState } from 'react';

function PhotoWidget(props) {
  const setData = props.setData;
  const data = props.data;
  const inputRef = useRef();


  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const imageFiles = Array.from(droppedFiles).filter((file) =>
      file.type.startsWith('image/')
    );
    setData({ fotos: [...data.fotos, ...imageFiles] });
  };

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    const imageFiles = Array.from(selectedFiles).filter((file) =>
      file.type.startsWith('image/')
    );

    setData({ fotos: [...data.fotos, ...imageFiles] });
  };

  const removeImage = (index) => {
    if (data.fotos) {
      const updatedfotos = [...data.fotos];
      updatedfotos.splice(index, 1); 
      setData({ fotos: updatedfotos });
    }
  };

  return (
    <div className='flex flex-col'>
      <h3>Fotos:</h3>
      <div
        className='h-[200px] flex flex-col border-[3px] border-slate-400 text-slate-400 fonst-semibold border-dashed rounded-lg p-4 justify-center items-center cursor-pointer'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => inputRef.current.click()}
      >
        <p>Arraste e solte uma foto aqui ou clique</p>
        <p>para adicionar fotos</p>
        <input
          type='file'
          multiple
          onChange={handleFileSelect}
          accept='image/*' 
          hidden
          ref={inputRef}
        />
      </div>
      <div className='flex flex-row overflow-x-hidden overflow-y-hidden h-[120px] p-1 justify-center gap-2'>
      {data && data.fotos && data.fotos.map((file, index) => (
          <div className='relative h-[100px]' key={index}>
            <span
              className='absolute bg-slate-500 rounded-full pl-2 pr-2 -pt-1 text-white -right-2 -top-1 cursor-pointer opacity-80 flex justify-center items-center'
              onClick={() => removeImage(index)} 
            >
              x
            </span>
            <img src={URL.createObjectURL(file)} alt={`Image ${index}`} className='h-full rounded-md' />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PhotoWidget;
