import React, { useState } from 'react'
import { GenPostAdmin } from '../../../api_client/api';
import { Alert } from '@mui/material';

function NovoPlano({handleRefresh}) {
    const [data, setData]= useState({});
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);
    const [formData, setFormData] = useState({
        ativo:           false,
        tipo_periodo:    "meses",
        periodo:         12,
        valor:           "",
        nome:            "",
        descricao:       "", 
    });
  
  const [validationErrors, setValidationErrors] = useState({
        tipo_periodo:    "",
        periodo:         "",
        valor:           "",
        nome:            "",
        descricao:       "", 
    });
  
    const handleChange = (e) => {     
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    
        setFormData({
            ...formData,
            [e.target.name]: value,
        });    
        setValidationErrors({
            ...validationErrors,
            [e.target.name]: value ? "" : "Campo obrigatório.",
        });
    }
  
    const handlePost = () =>{
        const errors = {};
        let hasErrors = false;
    
        for (const key in formData) {
          if (formData[key] === "") { 
            errors[key] = "Campo obrigatório.";
            hasErrors = true;
          }
        }
    
        setValidationErrors(errors);
    
        if (hasErrors) {
          setShowError(true);
          setMsg("Por favor, preencha todos os campos.");
    
          setTimeout(() => {
            setShowError(false);
            setMsg(null);
          }, 4000);
    
          return;
        }

      async function postData() {
        try {
          const response = await GenPostAdmin("/planos/store", formData);
          setData(response);
          handleRefresh(true);
        } catch (err) {
        }
      }
      postData();
    }
  return (
    <div className='rounded-md flex w-full bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
                    <div className=' w-[100%] p-2 '>
                        <div className='flex flex-col text-left overflow-hidden'>
                            <h2 className='input-classes text-lg'>Cadastrar novo Plano</h2>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Nome: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="nome"
                                    name="nome"
                                    value={formData.nome}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-col justify-between'>
                                <label className=''>Descricão: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="descricao"
                                    name="descricao"
                                    value={formData.descricao}
                                    onChange={handleChange}  
                                    />
                            </div>
                            <div className='flex flex-row justify-between flex-wrap'>
                                <div className='flex flex-col justify-between'>                                
                                    <label className=''>Tipo de período: </label>
                                    <select
                                        className="w-[200px] bg-slate-50 border rounded m-2 flex p-1.5"
                                        aria-label="tipo_periodo"
                                        onChange={handleChange}
                                        id="tipo_periodo"
                                        name="tipo_periodo"
                                        value={formData.tipo_periodo}
                                        error={!!validationErrors.tipo_periodo}
                                        helperText={validationErrors.tipo_periodo}
                                    >
                                        <option value={'dias'} >Dias</option>
                                        <option value={'meses'}>Meses</option>
                                        <option value={'anos'} >Anos</option>

                                    </select>
                                </div>
                                <div className='flex flex-col'>
                                <label className=''>Período: 
                                </label><input className={`w-[200px] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="periodo"
                                    name="periodo"
                                    type="number"
                                    value={formData.periodo}
                                    onChange={handleChange}  
                                    />
                                </div>
                                <div className='flex flex-col min-w-[120px]'>
                                <label className=''>Valor: 
                                </label><input className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="valor"
                                    name="valor"
                                    value={formData.valor}
                                    onChange={handleChange}  
                                    />
                                </div>
                                <div className='flex flex-row justify-start gap-2'>
                                <label className=''>Ativo: 
                                </label><input className={` bg-slate-50 border rounded m-2 flex p-1.5`}                 
                                    id="ativo"
                                    name="ativo"
                                    type="checkbox"
                                    value={formData.ativo}
                                    onChange={handleChange}  
                                    />
                            </div>
                            </div>

                            <div className='flex w-full justify-end gap-2 mt-4 p-4'>
                                {msg ? (
                                    <div className='h-[40px]'>
                                        <Alert severity="info" > {msg} </Alert>
                                    </div>) : (null)}
                                <button className='bg-cyan-600 h-[40px] w-[200px] rounded hover-bg-cyan-500' onClick={handlePost}>
                                    Cadastrar
                                </button>

                            </div>
                        </div>

                    </div>
                </div>
  )
}

export default NovoPlano