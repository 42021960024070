import React, { useState, useEffect } from 'react';
import { GenPostAdmin } from '../../../../api_client/api';
import { appurl } from '../../../../config';

function ListarDestaques({ refresh, setRefresh, data }) {
  const [scssMsg, setScssMsg] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleDelete = (id) => {
    async function removeDestaque(id) {
      try {
        const response = await GenPostAdmin(`/destaques/destroy/${id}`);
        setScssMsg(response.message);
        console.log(response);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
        setRefresh(true); // Trigger refresh after deleting an item
      } catch (err) {
        setScssMsg(err.message);
        setTimeout(() => {
          setScssMsg(null);
        }, 3000);
      }
    }
    removeDestaque(id);
  };

  return (
    <div className='w-[100%] flex flex-col text-left p-2'>
      {data && (
        <div className='flex flex-col gap-4'>
          <h3 className='input-classes text-md'>Destaques:</h3>
          <div className='w-full flex flex-row gap-3 overflow-x-scroll p-2'>
            {data.destaque && Array.isArray(data.destaque) && data.destaque.length > 0 ? data.destaque.map(item => (
              <div key={item.id} className='relative overflow-hidden min-h-[120px] min-w-[190px] max-h-[160px] max-w-[230px] rounded-md bg-slate-200 flex flex-col justify-center'>
                <img className='rounded-sm' src={`${appurl}/${item.receita.receita_fotos.find(img => img.capa)?.path}`} alt={item.receita.nome} />
                <button className='absolute -top-1 -right-1 bg-red-500 hover:bg-red-400 text-slate-100 px-2 rounded-md h-[30px]' onClick={() => handleDelete(item.id)}>Remover</button>
                <div className='h-[35px] w-full absolute bottom-0 flex justify-center align-middle items-center bg-slate-700 bg-opacity-50'><p className='text-slate-100 font-medium'>{item.receita.nome}</p></div>
              </div>
            )) : <div className='h-[50px] flex items-center'><p>Nenhum destaque cadastrado</p></div>}
          </div>

          <h3 className='input-classes text-md'>Tendências:</h3>
          <div className='w-full flex flex-row gap-3 overflow-x-scroll p-2'>
            {data.tendencias && Array.isArray(data.tendencias) && data.tendencias.length > 0 ? data.tendencias.map(item => (
              <div key={item.id} className='relative overflow-hidden min-h-[120px] min-w-[190px] max-h-[160px] max-w-[230px] rounded-md bg-slate-200 flex flex-col justify-center'>
                <img className='rounded-sm' src={`${appurl}/${item.receita.receita_fotos.find(img => img.capa)?.path}`} alt={item.receita.nome} />
                <button className='absolute -top-1 -right-1 bg-red-500 hover:bg-red-400 text-slate-100 px-2 rounded-md h-[30px]' onClick={() => handleDelete(item.id)}>Remover</button>
                <div className='h-[35px] w-full absolute bottom-0 flex justify-center align-middle items-center bg-slate-700 bg-opacity-50'><p className='text-slate-100 font-medium'>{item.receita.nome}</p></div>
              </div>
            )) : <div className='h-[50px] flex items-center'><p>Nenhuma tendência cadastrada</p></div>}
          </div>

          <h3 className='input-classes text-md'>Novidades:</h3>
          <div className='w-full flex flex-row gap-3 overflow-x-scroll p-2'>
            {data.novidades && Array.isArray(data.novidades) && data.novidades.length > 0 ? data.novidades.map(item => (
              <div key={item.id} className='relative overflow-hidden min-h-[120px] min-w-[190px] max-h-[160px] max-w-[230px] rounded-md bg-slate-200 flex flex-col justify-center'>
                <img className='rounded-sm' src={`${appurl}/${item.receita.receita_fotos.find(img => img.capa)?.path}`} alt={item.receita.nome} />
                <button className='absolute -top-1 -right-1 bg-red-500 hover:bg-red-400 text-slate-100 px-2 rounded-md h-[30px]' onClick={() => handleDelete(item.id)}>Remover</button>
                <div className='h-[35px] w-full absolute bottom-0 flex justify-center align-middle items-center bg-slate-700 bg-opacity-50'><p className='text-slate-100 font-medium'>{item.receita.nome}</p></div>
              </div>
            )) : <div className='h-[50px] flex items-center'><p>Nenhuma novidade cadastrada</p></div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default ListarDestaques;
