import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';

function PhoneValidation({ data, onInputChange, value, error  }) {

  return (

    <InputMask
      id={data.id}
      name={data.name}
      mask="(999) 99999-9999"
      className={`min-w-[250px] bg-slate-300 rounded w-full p-1 m-2 -mt-0 h-[35px] ${error  &&('border-[1px] border-red-500')} `}
      value={value}
      onChange={onInputChange}
    />
  );
}

function CPFValidation({ data, onInputChange, value, error  }) {
  return (
    <InputMask
      id={data.id}
      name={data.name}
      mask="999.999.999-99"
      className={`min-w-[250px] bg-slate-200 rounded  p-1 m-2 -mt-0 h-[35px] ${error  &&('border-[1px] border-red-500')} `}
      value={value}
      onChange={onInputChange}
    />
  );
}
function HourValidation({ data, onInputChange, value, error }) {
  return (
    <InputMask
      id={data.id}
      name={data.name}
      mask="99:99"
      className={`min-w-[250px] bg-slate-200 rounded  p-1 m-2 -mt-0 h-[35px] ${error  &&('border-[1px] border-red-500')} `}
      value={value}
      onChange={onInputChange}
    />
  );
}

function PasswordValidation({ data, onInputChange, value, error }) {
  return (
    <input 
        className={`min-w-[250px] w-full bg-slate-300 rounded  p-1 m-2 -mt-0 h-[35px] ${error  &&('border-[1px] border-red-500')} `}               
        id="password_confirmation"
        type='password'
        name={data.name}
        value={value}
        onChange={onInputChange}  
        />
  );
}

function Select({ data, onInputChange, customValue, values = {}, error }) {
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(Object.keys(values));
  const [inputValue, setInputValue] = useState('');

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleFilter = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setInputValue(inputValue);

    if (inputValue === '') {
      setFilteredData(Object.keys(values));
    } else {
      const filteredData = Object.keys(values).filter(key =>
        key.toLowerCase().includes(inputValue) || values[key].toLowerCase().includes(inputValue)
      );
      setFilteredData(filteredData);
    }
  };

  const handleSelect = (selectedKey) => {
    onInputChange(selectedKey);
    setOpen(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => setOpen(true)}
        className={`relative h-[40px] min-w-[250px] bg-slate-200 rounded m-2 -mt-[0px] pt-[7px] ${error ? 'border-[1px] border-red-500' : ''}`}
      >
        <p>{customValue ? values[customValue] : 'Selecione'}</p>
      </div>
      {open && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md w-64 max-h-[350px] overflow-y-auto"
        >
          <input
            type="text"
            className="border border-gray-300 rounded-md p-2 w-64"
            placeholder="Pesquisar"
            onChange={handleFilter}
            value={inputValue}
          />
          {filteredData.map((key) => (
            <div
              key={key}
              className="p-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelect(key)}
            >
              {values[key]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}


export default function Validator({ data, onInputChange, value, error, values, customValue }) {
    const ValidationTypes = {
      'telefone': PhoneValidation,
      'cpf': CPFValidation,
      'hora':HourValidation,
      'select':Select,
      'senha':PasswordValidation
    };
    const DynamicComponent = ValidationTypes[data.maskType];
    if (DynamicComponent){
        return <DynamicComponent data={data} onInputChange={onInputChange} value={value} error={error} values={values} customValue={customValue}/>;
    } else {
        return(     
        <InputMask
        id={data.id}
        type={data.type}
        name={data.name}
        className={`min-w-[98%] bg-slate-50 border rounded m-2 flex p-1.5 ${error &&('border-[1px] border-red-500') }`}
        value={value}
        onChange={onInputChange}
      />)
    }
    
  }