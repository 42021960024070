import { AdminClient } from "../api_client/axios";

async function UserAuth() {
  try {
    const res = await AdminClient.get('/auth/refresh');
    if (res.status === 200) {       
      return true;
      
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function AdminAuth() {
  try {
    const res = await AdminClient.get('/auth/refresh');  
    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {

  }
}

async function CombinedAuth() {
  let usrLog = false;
  let admLog = false;
  
  try {
    usrLog = await UserAuth();
    
    if (usrLog) {
      admLog = await AdminAuth();
    }
    
    if (usrLog && admLog) {
      return {user:true, adm:true};
    
    } else if (usrLog && !admLog) {
      return {user:true, adm:false}
    
    } else {
      return {user:false, adm:false};
    }
  } catch (error) {

  }
}

export { UserAuth, AdminAuth, CombinedAuth };
