import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { GenPost } from "../../api_client/api";

const DataGridAssinaturas = ({data}) => {
  const nav = useNavigate()
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [ativas, setAtivas] = useState('ativas');
  const [inativas, setInativas] = useState(false);
  const [res, setData] = useState({});
  const [filterText, setFilterText] = useState('');
  const dataGrid = (res) => {
    if (res && Array.isArray(res)) {
      return res.map((item) => ({
        id: item.id,
        user: item.user.name,
        plano: item.plano.nome,
        status: item.ativa === 1 ?'Ativa':'Inativa',
        validade:item.validade,
      }));
    } else {
      return [];
    }
  };
  const [gridData, setGridData] = useState(dataGrid(data.ativas))
  
  const currentItems = (gridData || []).slice(indexOfFirstItem, indexOfLastItem);


  const handleCheckboxChange = (checkbox) => {
    setAtivas(checkbox === 'ativas');
    setInativas(checkbox === 'inativas');
    setGridData(
      checkbox === 'inativas' ? dataGrid(data.inativas) :
      dataGrid(data.ativas)
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((gridData?.length || 0) / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const handleDelete = async (id) => {
    try {
      const response = await GenPost(`/cardump/${id}`);
      setData(response);
    } catch (error) {
      console.error('Erro ao atualizar o registro:', error);
      // Trate o erro conforme necessário
    }

  };

  const handleSold = async (id) => {
    try {
      const response = await GenPost(`/carsold/${id}`);
      setData(response);
    } catch (error) {
      console.error('Erro ao atualizar o registro:', error);
      // Trate o erro conforme necessário
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.scrollTop = 0;
  }, [currentPage])

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterText(value);

    if (value === '') {
      setGridData(ativas ? dataGrid(data.ativas) : dataGrid(data.inativas));
    } else {
      const filteredData = dataGrid(ativas ? data.ativas : data.inativas).filter(item => 
        item.user.toLowerCase().includes(value)
      );
      setGridData(filteredData);
      setCurrentPage(1);
    }
  };

  function formatDate(dateString) {
    const dateParts = dateString.split('-');
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  }
  
  return (<>
   
    <div className="container mx-auto my-4 ">
      <div className="overflow-x-auto h-[350px] w-[100%]">
      <div className="flex justify-between">
            <div className="flex gap-2">
            <input
                className="rounded-md p-2 bg-slate-200 m-1"
                placeholder="Pesquisar"
                onChange={handleFilter}
                value={filterText}
              />
              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={ativas}
                  onChange={() => handleCheckboxChange('ativas')}
                />
                <p className="-mt-2">Ativas</p>
                
              </label>

              <label  className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={inativas}
                  onChange={() => handleCheckboxChange('inativas')}
                />
                <p className="-mt-2">Inativas</p>
              </label>
            </div>
          </div>
        <table ref={tableRef} className="min-w-full ">
          <thead className="justify content-between">

            <tr>
              <th className="px-6 py-3 bg-gray-300 text-left w-10">ID</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-2/8">Usuário</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-24">Plano</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-2/8">Status</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-1/8">Validade</th>
              <th className="px-6 py-3 bg-gray-300 text-left w-1/8">Opções</th>
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((item) => (
                <tr key={item.id}>
                  <td className="px-6 py-4"><div className=" flex justify-between w-10">{item.id}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-2/8">{item.user}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-24">{item.plano}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-1/8">{item.status}</div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-1/8">{formatDate(item.validade)}</div></td>
                  <td className="px-6 py-4">
                    <div className=" flex justify-between w-1/8">
                      <button onClick={() => nav(`edit/${item.id}`)}>Detalhes</button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                  <td className="px-6 py-4"><div className=" flex justify-between w-24"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-72"></div></td>
                  <td className="px-6 py-4"><div className=" flex justify-between w-32"></div></td>
                  <td className="px-6 py-4">
                    <div className=" flex justify-between w-32">
                      
                    </div>
                  </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-center">
      {pageNumbers && pageNumbers.length > 0 ? (
        pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            className={`mx-2 px-3 py-2 rounded-md ${
              currentPage === pageNumber
                ? "bg-red-300 text-pink-950"
                : "bg--pink-950 text-red-300"
            }`}
            onClick={() => handleChangePage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))
      ) : (
        <p className="mx-2 px-3 py-2">Nenhuma página disponível.</p>
      )}
      </div>
    </div>
    </>
  );
};

export default DataGridAssinaturas;