import React, {useEffect, useState} from 'react'
import { GenGetAdmin, GenPostAdmin } from '../../../api_client/api';
import { Alert, CircularProgress } from '@mui/material';
import FormInput from '../../../components/Inputs/FormInputs';

function PainelPagSeguro() {
    const [loading, setLoading] = useState(true);
    const [pagamentos, setPagamentos] = useState([]);
    const [scssMsg, setScssMsg] = useState(null);
    const [showErrors, setShowError] = useState(false);
    const [msg, setMsg] = useState(null);

    const [data, setData] = useState({
        plataforma:'pagseguro',
        url_base:'',
        token:''
      });

    const [validationErrors, setValidationErrors] = useState({
        plataforma:'',
        url_base:'',
        token:''
        });
      
    const handleForm = (name, value) =>{
        setData({
            ...data, 
            [name]:value
        })

        setValidationErrors({
            ...validationErrors,
            [name]: value ? "" : "Campo obrigatório.",
          });
    }

    const inputData = [
        {
           'type':'string',
           'maskType':'texto',
           'name':'url_base',
           'label':'Url Base',
           'required':true,
           'id':'url_base',
           'validationErrors':validationErrors.url_base
         },
         {
            'type':'string',
            'maskType':'texto',
            'name':'token',
            'label':'Token',
            'required':true,
            'id':'token',
            'validationErrors':validationErrors.token
          },
       ]
       const handlePost = () =>{
        const errors = {};
        let hasErrors = false;
    
        for (const key in data) {
          if (data[key] === "") { 
            errors[key] = "Campo obrigatório.";
            hasErrors = true;
          }
        }
    
        setValidationErrors(errors);
    
        if (hasErrors) {
          setShowError(true);
          setMsg("Por favor, preencha todos os campos obrigatórios.");
    
          setTimeout(() => {
            setShowError(false);
            setMsg(null);
          }, 4000);
    
          return;
        }
  
          async function postData() {
            try {
              const response = await GenPostAdmin("/token/store", data)
              setScssMsg(response.message);
              console.log(response)
              setData({
                descricao:'',
                nome:'',
                premium:true
              });
              setTimeout(() => {
                setScssMsg(null);
              }, 3000);

            } catch (err) {
              setScssMsg(err.message);
              setTimeout(() => {
                setScssMsg(null);
              }, 3000);
            }
          }
          postData();
        }

    async function getPagamentos() {
        try {
          const response = await GenGetAdmin(`/pagamentos/pagseguro`);
          setPagamentos(response.data);
          setLoading(false);

        } catch (err) {
            setLoading(false);
        }
      }
      useEffect(() => {
        getPagamentos();    
      }, []);

  return (
    <>
    {!loading ?(
        <div>
        <h2 className='text-left font-semibold'>Painel PagSeguro</h2>
        <div className='p-4 min-h-[100px] flex justify-center align-middle items-center'>
            {pagamentos && pagamentos.length === 0 ? (
            <p>Nenhum Pagamento capturado</p>
            ) : (
            <ul>
                {pagamentos.map((pagamento, index) => (
                <li key={index}></li>
                ))}
            </ul>
            )}
        </div>
        <h2 className='text-left font-semibold'>Token de integração:</h2>  

        <div className='flex flex-row w-full p-4 justify-start text-left'>
            {inputData.map(item=>(
                <div className='w-[50%]' key={item.id}>
                    <FormInput data={item} onInputChange={handleForm} error={!showErrors? false: item.validationErrors}/>
                </div>
            ))}     
            <div className='w-[50%] flex align-bottom'>
                <button className='p-2 bg-blue-400 h-[35px] self-center mt-[10px] font-semibold rounded-md ml-4 px-3' onClick={handlePost}>Enviar</button>
            </div>
        </div>
        <div className='w-[50%] flex align-bottom'>
            {msg ? (
              <div className='h-[40px]'>
                <Alert severity="info" > {msg} </Alert>
              </div>) : (null)}
            {scssMsg ? 
              <div >
                <Alert severity="info" sx={{width:320}} > {scssMsg} </Alert>
              </div>
            : null}
        </div>
      </div>
      ):(
        <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
            <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
                <div className='flex flex-col items-center'>
                    <CircularProgress />
                </div>
            </div>
        </div>
    )}
    </>
  )
}

export default PainelPagSeguro