import React, { useState, useEffect } from 'react'
import DataGridUsuarios from '../../../components/AdminComponents/DataGridsUsuarios';
import { GenGetAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';

function UsuariosIndex() {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})

  useEffect(() => {
      async function getData() {
        try {
          const response = await GenGetAdmin("/adminuser/index")
          setData(response);
          setLoading(false)
          console.log(response)
        } catch (err) {
        }
      }
      getData();
    }, []);
  
  return (
    <>{!loading ?(

    <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll no-scrollbar'>
        <div className=' w-[100%] p-2 '>
        <div className='flex flex-col text-left'>
            <h2 className='input-classes text-lg'>Usuários</h2>
            <DataGridUsuarios data={data.users} />
            <div className='flex flex-col'>
              <h3 className='font-bold'>Dados gerais:</h3>
              <div className='flex justify-between mt-4 p-4 md:flex-row flex-col gap-3'>
                <div className='flex flex-row rounded-lg bg-slate-300 p-4'>
                  <p>Ativos: </p>  
                  <p className='ml-2'>{data.metricas.ativos}</p>
                </div>
                <div className='flex flex-row rounded-lg bg-slate-300 p-4'>
                  <p>Inativos: </p>  
                  <p className='ml-2'>{data.metricas.inativos}</p>
                </div>
                <div className='flex flex-row rounded-lg bg-slate-300 p-4'>
                  <p>Cadastrados: </p>  
                  <p className='ml-2'>{data.metricas.cadastrados}</p>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>

    ) : (
    <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <CircularProgress />
        </div>
      </div>
    </div>)}</>
    
    
  )
}

export default UsuariosIndex