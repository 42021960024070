import React, { useState, useEffect, useRef } from "react";
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TicketModal from "../../AdminRoot/scenes/Tickets/TicketModal";
import { GenPost } from "../../api_client/api";

const DataGridTickets = ({ data, setRefresh }) => {
  const [pointer, setPointer] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [abertos, setAbertos] = useState('abertos');
  const [resolvidos, setResolvidos] = useState(false);
  const [res, setData] = useState({});
  const [filterText, setFilterText] = useState('');


  const truncateTicket = (ticket) => {
    return ticket.length > 60 ? `${ticket.substring(0, 60)}...` : ticket;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Extract parts of the date
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  const dataGrid = (res) => {
    if (res && Array.isArray(res)) {
      return res.map((item) => ({
        id: item.id,
        ticket: item.ticket,
        user: item.user ? item.user : "Usuário desconhecido",
        data:formatDate(item.created_at)
      }));
    } else {
      return [];
    }
  };

  const [gridData, setGridData] = useState(dataGrid(data.abertos));

  const currentItems = (gridData || []).slice(indexOfFirstItem, indexOfLastItem);

  const handleCheckboxChange = (checkbox) => {
    setAbertos(checkbox === 'abertos');
    setResolvidos(checkbox === 'resolvidos');
    setGridData(
      checkbox === 'resolvidos' ? dataGrid(data.resolvidos) :
        dataGrid(data.abertos)
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil((gridData?.length || 0) / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleModal = (item) => {
    setModalActive(true);
    setPointer(item);
  };

  const handleSolved = async (id) => {
    try {
      //
    } catch (error) {
      // Handle the error as needed
    }
  };

  const tableRef = useRef();

  useEffect(() => {
    tableRef.current.scrollTop = 0;
  }, [currentPage]);

  const handleFilter = (e) => {
    const value = e.target.value.toLowerCase();
    setFilterText(value);
    if (value === '') {
      setGridData(abertos ? dataGrid(data.abertos) : dataGrid(data.resolvidos));
    } else {
      const filteredData = dataGrid(abertos ? data.abertos : data.resolvidos).filter(item =>
        item.user.toLowerCase().includes(value)
      );
      setGridData(filteredData);
      setCurrentPage(1);
    }
  };

  const closeModal = () => {
    setModalActive(false);
    setPointer(null);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius:5,
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="container mx-auto my-4 ">
        <div className="overflow-x-auto h-[350px] w-[100%]">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <input
                className="rounded-md p-2 bg-slate-200 m-1"
                placeholder="Pesquisar"
                onChange={handleFilter}
                value={filterText}
              />
              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={abertos}
                  onChange={() => handleCheckboxChange('abertos')}
                />
                <p className="-mt-2">Abertos</p>
              </label>

              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  checked={resolvidos}
                  onChange={() => handleCheckboxChange('resolvidos')}
                />
                <p className="-mt-2">Resolvidos</p>
              </label>
            </div>
          </div>
          <table ref={tableRef} className="min-w-full ">
            <thead className="justify content-between">
              <tr>
                <th className="px-2 py-2 bg-gray-300 text-left w-[20px]">ID</th>
                <th className="px-2 py-2 bg-gray-300 text-left w-[50px]">Usuário</th>
                <th className="px-2 py-2 bg-gray-300 text-left w-[120px]">Ticket</th>
                <th className="px-2 py-2 bg-gray-300 text-left w-[100px]">Opções</th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <tr key={item.id} className="hover:bg-slate-200 border-slate-400 border-b-[1px]">
                    <td className="px-2 py-2 w-[20px]"><p className="text-[10pt]">{item.id}</p></td>
                    <td className="px-2 py-2 w-[50px]"><p className="text-[10pt]">{item.user.name}</p></td>
                    <td className="px-2 py-2 w-[120px]"><p className="text-[10pt]">{truncateTicket(item.ticket)}</p></td>
                    <td className="px-2 py-2 w-[100px]">
                      <div className="flex justify-between">
                        <button onClick={() => handleModal(item)}>Visualizar</button>
                        <button onClick={() => handleSolved(item.id)}><CheckIcon sx={{ color: "#363636", "&:hover": { color: "#696969" } }} /></button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-6 py-4"><div className="flex justify-between w-24"></div></td>
                  <td className="px-6 py-4"><div className="flex justify-between w-72"></div></td>
                  <td className="px-6 py-4"><div className="flex justify-between w-32"></div></td>
                  <td className="px-6 py-4">
                    <div className="flex justify-between w-32"></div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-center">
          {pageNumbers && pageNumbers.length > 0 ? (
            pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                className={`mx-2 px-3 py-2 rounded-md ${
                  currentPage === pageNumber
                    ? "bg-red-300 text-pink-950"
                    : "bg--pink-950 text-red-300"
                }`}
                onClick={() => handleChangePage(pageNumber)}
              >
                {pageNumber}
              </button>
            ))
          ) : (
            <p className="mx-2 px-3 py-2">Nenhuma página disponível.</p>
          )}
        </div>
      </div>

      <Modal
        open={modalActive}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {pointer && <TicketModal data={pointer} onClose={closeModal} setRefresh={setRefresh} />}
        </Box>
      </Modal>
    </>
  );
};

export default DataGridTickets;
