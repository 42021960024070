import React, { useState, useEffect } from 'react'
import DataGridLeads from '../../../components/AdminComponents/DataGridsLeads'
import FormInputsLeads from '../../../components/Inputs/FormInputsLeads'
import { GenGetAdmin } from '../../../api_client/api';
import { CircularProgress } from '@mui/material';

function LeadsIndex() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({})
  const [gridData, setGridData] = useState({})
  



  useEffect(() => {
      async function getData() {
        try {
          const response = await GenGetAdmin("/receitas/adminreceitas")
          console.log(response)
          setData(response);
          setGridData(data);
          setLoading(false);
          
        } catch (err) {
        }
      }
      getData();
    }, []);
    
  return (<>
    { !loading ? (
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
    <div className=' w-[100vw] sm:w-[85vw] h-[89vh] p-2 '>
    <div className='flex flex-col text-left'>
        <h2 className='input-classes text-lg'>Leads</h2>
        <DataGridLeads data={gridData}/>
        <div className='flex flex-col'>
          <h3 className='font-bold'>Dados Sobre Cadastros:</h3>
          <div className='flex justify-start gap-4 mt-4 p-4 lg:flex-row flex-col'>
            <div className='flex justify-start md:flex-row flex-col mt-4 p-4 gap-4 rounded-lg bg-slate-300'>
                <div className='flex flex-row'>
                  <p>Leads cadastrados: </p>  
                  <p className='ml-2'>{data.metricas.total_cadastrado}</p>
                </div>
                <div className='flex flex-row'>
                  <p>Via cliente: </p>  
                  <p className='ml-2'>{data.metricas.via_cliente}</p>
                </div>
                <div className='flex flex-row'>
                  <p>Via painel: </p>  
                  <p className='ml-2'>{data.metricas.via_painel}</p>
                </div>
            </div>
            <div className='flex justify-start mt-4 p-4 gap-4 rounded-lg bg-slate-300'>
              <div className='flex flex-row'>
                <p>Leads Atívos: </p>  
                <p className='ml-2'>{data.metricas.ativo}</p>
              </div>
              <div className='flex flex-row'>
                <p>Leads Inativos: </p>  
                <p className='ml-2'>{data.metricas.inativo}</p>
              </div>
            </div>
          </div>
          <FormInputsLeads/>

        </div>
    </div>
    </div>
  </div>
    ) : (
      <div className='rounded-md flex flex-grow bg-slate-50 overflow-x-hidden overflow-y-scroll'>
      <div className='w-[100vw] sm:w-[85vw] h-[89vh] p-2 flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <CircularProgress />
        </div>
      </div>
    </div>
    )}
  </>
  )
}

export default LeadsIndex